import { CurriculumBoard } from "../../../features/EditTopics/EditTopicsApiSlice";

export enum Questiontype {
  MCQ = "mcq",
}

export enum QuestionComponentType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  INLINE_IMAGES = "INLINE_IMAGES",
  AUDIO = "AUDIO",
}

export enum OptionType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
}

export interface QuestionComponentBase {}

export interface OptionBase {
  isCorrect: boolean;
}

export interface TextComponent extends QuestionComponentBase {
  type: QuestionComponentType.TEXT;
  value: string;
}

export interface ImageComponent extends QuestionComponentBase {
  type: QuestionComponentType.IMAGE;
  value: string;
}

export interface InlineImagesComponent extends QuestionComponentBase {
  type: QuestionComponentType.INLINE_IMAGES;
  value: string[];
}

export interface AudioComponent extends QuestionComponentBase {
  type: QuestionComponentType.AUDIO;
  value: string;
}

export type Component =
  | TextComponent
  | ImageComponent
  | InlineImagesComponent
  | AudioComponent;

export interface TextOption extends OptionBase {
  type: OptionType.TEXT;
  text: string;
}

export interface ImageOption extends OptionBase {
  type: OptionType.IMAGE;
  text: string;
}

export interface AudioOption extends OptionBase {
  type: OptionType.AUDIO;
  text: string;
}

export type MCQOption = TextOption | ImageOption | AudioOption;

export type MetaComponent = {
  type:"hint";
  text: string;
};

export interface IMCQQuestion {
  en_question: Component[];
  en_options: MCQOption[];
  hi_question?: null;
  hi_options?: null;
  topic?: string;
  subtopic?: string;
  type?: string;
  formatType?: string;
  tags?: string[];
  conceptTags?: string[];
  concept?: string[];
  allConceptTags?: { id: string; name: string }[];
  preRequisiteConcepts?: string[];
  bloomLevel?: number;
  level?: null;
  meta: MetaComponent[];
  explaination?: string;
  isCompetitionQuestion?: null;
  status?: string;
  problemStatement?: any;
  isActive?: boolean;
  questionHash?: string;
  id?: string;
  _id?: string;
  ClassName?: string;
  subtopicName?: string;
  topicName?: string;
  category?: string;
  subCategory?: string;
  difficultyLevel?: number;
  author?: string;
  source?: string;
  year?: number | null;
  curriculumBoard?: CurriculumBoard | "";
}


export interface IMCQCaseQuestion {
  en_question: Component[];
  en_options: MCQOption[];
  hi_question?: null;
  hi_options?: null;
  topic?: string;
  subtopic?: string;
  type?: string;
  formatType?: string;
  tags?: string[];
  conceptTags?: string[];
  concept?: string[];
  allConceptTags?: { id: string; name: string }[];
  preRequisiteConcepts?: string[];
  bloomLevel?: number;
  level?: null;
  meta?: MetaComponent[];
  explaination?: string;
  isCompetitionQuestion?: null;
  status?: string;
  problemStatement?: null;
  isActive?: boolean;
  questionHash?: string;
  id?: string;
  ClassName?: string;
  subtopicName?: string;
  topicName?: string;
  gems?: number;
  curriculumBoard?: CurriculumBoard | "";
}


export interface OriginalQuestionFormat {
  Grade: string;
  subject: string;
  chapter: string;
  conceptTags: string[];
  type: string;
  Source: string;
  Author?: string;
  bloomLevel: number;
  category?: string;
  subCategory?: string;
  curriculumBoard?: CurriculumBoard | "";
  questions: {
    difficultylevel?: string | undefined;
    question: string;
    options: string[];
    answer: string;
    explanation: string;
    hint: string;
    year?: string;
  }[];
}