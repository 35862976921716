import { Button, Card, message, Typography } from "antd";
import React, { useState } from "react";
import { QuestionPreviewer } from "../../../quiz/CompetitionForms/CompetitionFormForClone";
import {
  IMCQCaseQuestion,
  IMCQQuestion,
} from "../../../components/dash-components/Mcq/types";
import { usePublishActivityMutation } from "../SchoolsApiSlice";

interface Question {
  id: string;
  // ... other question properties
}

interface Student {
  id: string;
  name: string;
  questions: IMCQQuestion[];
}

interface RemedialQuestionsProps {
  data: Student[];
}

const RemedialQuestions: React.FC<RemedialQuestionsProps> = ({ data }) => {
  const [publishedStudents, setPublishedStudents] = useState<string[]>([]);
  const [isPublishingAll, setIsPublishingAll] = useState(false);
  const [publishActivity, { isLoading, isSuccess, isError, error }] =
    usePublishActivityMutation();

  const handlePublishForStudent = async (studentId: string) => {
    try {
      // Here you would make the API call with the student's question IDs
      const student = data.find((s) => s.id === studentId);
      const questionIds = student?.questions.map((q) => q.id) || [];
      const result = await publishActivity({
        activityIds: [studentId],
      }).unwrap();

      // API call would go here
      // await publishQuestions(questionIds);

      setPublishedStudents((prev) => [...prev, studentId]);
      message.success(`Published questions for ${student?.name}`);
    } catch (error) {
      message.error("Failed to publish questions");
      console.error(error);
    }
  };

  const handlePublishAll = async () => {
    try {
      setIsPublishingAll(true);
      // Here you would make the API call with all question IDs
      const allQuestionIds = data.flatMap((student) =>
        student.questions.map((question) => question.id)
      );

      // API call would go here
      // await publishQuestions(allQuestionIds);

      const allStudentIds = data.map((student) => student.id);
      const result = await publishActivity({
        activityIds: allStudentIds,
      }).unwrap();
      setPublishedStudents(allStudentIds);
      message.success("Published all questions successfully");
    } catch (error) {
      message.error("Failed to publish all questions");
      console.error(error);
    } finally {
      setIsPublishingAll(false);
    }
  };

  return (
    <div className="space-y-6">
      {data.map((student) => (
        <Card key={student.id} className="p-6">
          <div className="flex justify-between items-center mb-4">
            <Typography className="font-bold text-xl">
              {student.name}'s Questions
            </Typography>
            <Button
              onClick={() => handlePublishForStudent(student.id)}
              disabled={publishedStudents.includes(student.id)}
            >
              {publishedStudents.includes(student.id) ? "Published" : "Publish"}
            </Button>
          </div>

          <QuestionPreviewer selectedQuestions={student.questions} />
        </Card>
      ))}

      <div className="flex justify-end mt-6">
        <Button
          onClick={handlePublishAll}
          disabled={isPublishingAll || publishedStudents.length === data.length}
        >
          {isPublishingAll ? "Publishing All..." : "Publish All"}
        </Button>
      </div>
    </div>
  );
};

export default RemedialQuestions;
