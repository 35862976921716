import React from 'react';
import { Filter, Calendar } from 'lucide-react';

const EmptyState = ({ hasDateSelected = false }) => {
  return (
    <div className="flex flex-col items-center justify-center p-12 min-h-[400px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
      <div className="rounded-full bg-gray-100 p-4 mb-6">
        {hasDateSelected ? (
          <Filter className="w-12 h-12 text-gray-400" />
        ) : (
          <Calendar className="w-12 h-12 text-gray-400" />
        )}
      </div>
      
      <h3 className="text-xl font-semibold text-gray-700 mb-2">
        {hasDateSelected ? 'No Activities Found' : 'Select a Date'}
      </h3>
      
      <p className="text-gray-500 text-center max-w-md">
        {hasDateSelected 
          ? 'No activities found for the selected filters. Try selecting a different date or grade.'
          : 'Please select a date from the calendar above to view available activities.'}
      </p>
    </div>
  );
};

export default EmptyState;