// import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { Form, TreeSelect } from "antd";
// import type { TreeSelectProps } from "antd";
// import { FormInstance } from "antd/lib/form";
// import { ClassData } from "../../../components/dash-components/TopicSelector";

// interface Subtopic {
//   id: string;
//   name: string;
// }

// interface Topic {
//   id: string;
//   name: string;
//   subtopics: Subtopic[];
// }

// interface Category {
//   id: string;
//   name: string;
//   topics: Topic[];
// }

// interface CompetitionScope {
//   categoryIds?: string[];
//   topicIds?: string[];
//   subtopicIds?: string[];
// }

// interface Competition {
//   competitionScope?: CompetitionScope;
// }

// interface Props {
//   classData: ClassData[] | undefined;
//   competition?: Competition;
//   form: FormInstance;
//   selectedCategoryIds: string[]; // categoryIds
//   selectedTopicIds: string[]; // topicIds
//   selectedSubtopicIds?: string[]; // subtopicIds
//   setSelectedTopicIds: Dispatch<SetStateAction<string[]>>;
//   setSelectedSubtopicIds?: Dispatch<SetStateAction<string[]>>;
//   setSelectedCategoryIds: Dispatch<SetStateAction<string[]>>;
// }
// //   selectedTopicIds,
//   //   selectedSubtopicIds,
// //   setSelectedTopicIds,
//   //   setSelectedSubtopicIds,

// interface TreeNode {
//   title: string;
//   value: string;
//   key: string;
//   selectable: boolean;
//   children?: TreeNode[];
//   name?: string; // Add this for custom label display
// }

// export const TopicSubtopicSelector: React.FC<Props> = ({
//   classData,
//   competition,
//   form,
//   selectedCategoryIds,
//   setSelectedCategoryIds,
// }) => {
//   //   const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
//       const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
//   const [selectedSubtopicIds, setSelectedSubtopicIds] = useState<string[]>([]);

//   useEffect(() => {
//     if (competition?.competitionScope) {
//       const {
//         categoryIds = [],
//         topicIds = [],
//         subtopicIds = [],
//       } = competition.competitionScope;
//       setSelectedCategoryIds(categoryIds);
//       setSelectedTopicIds(topicIds);
//       setSelectedSubtopicIds(subtopicIds);

//       form.setFieldsValue({
//         topicIds,
//         subtopicIds,
//       });
//     }
//   }, [competition, form]);

//   const getTopicTreeData = (): TreeNode[] => {
//     return selectedCategoryIds.map((categoryId) => {
//       const category = classData?.find((cat) => cat.id === categoryId);
//       return {
//         title: category?.name || "",
//         value: categoryId,
//         key: categoryId,
//         selectable: false,
//         children:
//           category?.topics.map((topic) => ({
//             title: topic.name,
//             value: topic.id,
//             key: topic.id,
//             name: topic.name,
//             selectable: true,
//           })) || [],
//       };
//     });
//   };

//   const getSubtopicTreeData = (): TreeNode[] => {
//     return selectedCategoryIds.map((categoryId) => {
//       const category = classData?.find((cat) => cat.id === categoryId);

//       const filteredTopics = category?.topics.filter((topic) =>
//         selectedTopicIds.includes(topic.id)
//       );

//       return {
//         title: category?.name || "",
//         value: categoryId,
//         key: categoryId,
//         selectable: false,
//         children:
//           filteredTopics?.map((topic) => ({
//             title: topic.name,
//             value: topic.id,
//             key: topic.id,
//             name: topic.name,
//             selectable: false,
//             children: topic.subtopics.map((subtopic) => ({
//               title: subtopic.name,
//               value: subtopic.id,
//               key: subtopic.id,
//               name: subtopic.name, // Add name property for display
//               selectable: true,
//             })),
//           })) || [],
//       };
//     });
//   };

//   const handleTopicChange: TreeSelectProps["onChange"] = (newTopicIds) => {
//     const topicIdsArray = (
//       Array.isArray(newTopicIds) ? newTopicIds : [newTopicIds]
//     ) as string[];
//     setSelectedTopicIds(topicIdsArray);
//     setSelectedSubtopicIds([]);
//     form.setFieldsValue({ subtopicIds: [] });
//   };

//   const handleSubtopicChange: TreeSelectProps["onChange"] = (
//     newSubtopicIds
//   ) => {
//     const subtopicIdsArray = (
//       Array.isArray(newSubtopicIds) ? newSubtopicIds : [newSubtopicIds]
//     ) as string[];
//     setSelectedSubtopicIds(subtopicIdsArray);
//   };

//   return (
//     <>
//       <Form.Item label="Topics" name="topicIds">
//         <TreeSelect<string[]>
//           disabled={selectedCategoryIds.length === 0}
//           treeData={getTopicTreeData()}
//           value={selectedTopicIds}
//           onChange={handleTopicChange}
//           treeCheckable={true}
//           showCheckedStrategy={TreeSelect.SHOW_CHILD}
//           placeholder="Select Topics"
//           style={{ width: "100%" }}
//           treeNodeLabelProp="name"
//         />
//       </Form.Item>

//       <Form.Item label="Subtopics" name="subtopicIds">
//         <TreeSelect<string[]>
//           disabled={selectedTopicIds.length === 0}
//           treeData={getSubtopicTreeData()}
//           value={selectedSubtopicIds}
//           onChange={handleSubtopicChange}
//           treeCheckable={true}
//           showCheckedStrategy={TreeSelect.SHOW_CHILD}
//           placeholder="Select Subtopics"
//           style={{ width: "100%" }}
//           treeNodeLabelProp="name"
//         />
//       </Form.Item>
//     </>
//   );
// };

// export default TopicSubtopicSelector;









import React, { useEffect, useState } from 'react';
import { Form, TreeSelect } from 'antd';
import type { TreeSelectProps } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ClassData } from '../../../components/dash-components/TopicSelector';

interface Subtopic {
  id: string;
  name: string;
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

interface Category {
  id: string;
  name: string;
  topics: Topic[];
}

interface CompetitionScope {
  categoryIds?: string[];
  topicIds?: string[];
  subtopicIds?: string[];
}

interface Competition {
  competitionScope?: CompetitionScope;
}

interface Props {
  classData: ClassData[] | undefined;
  competition?: Competition;
  form: FormInstance;
  selectedCategoryIds: string[];
  setSelectedCategoryIds: (categoryIds: string[]) => void;
  setTopicIds: (topicIds: string[]) => void;
  setSubtopicIds: (subtopicIds: string[]) => void;
}

interface TreeNode {
  title: string;
  value: string;
  key: string;
  selectable: boolean;
  children?: TreeNode[];
  name?: string;
}

export const TopicSubtopicSelector: React.FC<Props> = ({
  classData,
  competition,
  form,
  selectedCategoryIds,
  setSelectedCategoryIds,
  setTopicIds,
  setSubtopicIds,
}) => {
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [selectedSubtopicIds, setSelectedSubtopicIds] = useState<string[]>([]);

  // Helper function to get all valid subtopic IDs for given topic IDs
  const getValidSubtopicIds = (topicIds: string[]): string[] => {
    const validSubtopicIds = new Set<string>();
    
    selectedCategoryIds.forEach(categoryId => {
      const category = classData?.find(cat => cat.id === categoryId);
      category?.topics
        .filter(topic => topicIds.includes(topic.id))
        .forEach(topic => {
          topic.subtopics.forEach(subtopic => {
            validSubtopicIds.add(subtopic.id);
          });
        });
    });
    
    return Array.from(validSubtopicIds);
  };

  useEffect(() => {
    if (competition?.competitionScope) {
      const {
        topicIds = [],
        subtopicIds = [],
      } = competition.competitionScope;

      setSelectedTopicIds(topicIds);
      setSelectedSubtopicIds(subtopicIds);

      form.setFieldsValue({
        topicIds,
        subtopicIds,
      });
    }
  }, [competition, form]);

  const getTopicTreeData = (): TreeNode[] => {
    return selectedCategoryIds.map(categoryId => {
      const category = classData?.find(cat => cat.id === categoryId);
      return {
        title: category?.name || "",
        value: categoryId,
        key: categoryId,
        selectable: false,
        children: category?.topics.map(topic => ({
          title: topic.name,
          value: topic.id,
          key: topic.id,
          name: topic.name,
          selectable: true,
        })) || [],
      };
    });
  };

  const getSubtopicTreeData = (): TreeNode[] => {
    return selectedCategoryIds.map(categoryId => {
      const category = classData?.find(cat => cat.id === categoryId);

      const filteredTopics = category?.topics.filter(topic =>
        selectedTopicIds.includes(topic.id)
      );

      return {
        title: category?.name || "",
        value: categoryId,
        key: categoryId,
        selectable: false,
        children: filteredTopics?.map(topic => ({
          title: topic.name,
          value: topic.id,
          key: topic.id,
          name: topic.name,
          selectable: false,
          children: topic.subtopics.map(subtopic => ({
            title: subtopic.name,
            value: subtopic.id,
            key: subtopic.id,
            name: subtopic.name,
            selectable: true,
          })),
        })) || [],
      };
    });
  };

  const handleTopicChange: TreeSelectProps['onChange'] = (newTopicIds) => {
    const topicIdsArray = (Array.isArray(newTopicIds) ? newTopicIds : [newTopicIds]) as string[];
    setSelectedTopicIds(topicIdsArray);
    setTopicIds(topicIdsArray);

    // Get all valid subtopic IDs for the selected topics
    const validSubtopicIds = getValidSubtopicIds(topicIdsArray);

    // Filter out invalid subtopics from the current selection
    const newSubtopicIds = selectedSubtopicIds.filter(id => 
      validSubtopicIds.includes(id)
    );

    setSelectedSubtopicIds(newSubtopicIds);
    form.setFieldsValue({ 
      topicIds: topicIdsArray,
      subtopicIds: newSubtopicIds 
    });
  };

  const handleSubtopicChange: TreeSelectProps['onChange'] = (newSubtopicIds) => {
    const subtopicIdsArray = (Array.isArray(newSubtopicIds) ? newSubtopicIds : [newSubtopicIds]) as string[];
    setSelectedSubtopicIds(subtopicIdsArray);
    setSubtopicIds(subtopicIdsArray);
    form.setFieldsValue({ subtopicIds: subtopicIdsArray });
  };

  return (
    <>
      <Form.Item label="Topics" name="topicIds">
        <TreeSelect<string[]>
          disabled={selectedCategoryIds.length === 0}
          treeData={getTopicTreeData()}
          value={selectedTopicIds}
          onChange={handleTopicChange}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          placeholder="Select Topics"
          style={{ width: "100%" }}
          treeNodeLabelProp="name"
        />
      </Form.Item>

      <Form.Item label="Subtopics" name="subtopicIds">
        <TreeSelect<string[]>
          disabled={selectedTopicIds.length === 0}
          treeData={getSubtopicTreeData()}
          value={selectedSubtopicIds}
          onChange={handleSubtopicChange}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          placeholder="Select Subtopics"
          style={{ width: "100%" }}
          treeNodeLabelProp="name"
        />
      </Form.Item>
    </>
  );
};

export default TopicSubtopicSelector;