import React, { useRef, useState } from "react";
import {
  Button,
  Input,
  Form,
  message,
  Space,
  Typography,
  List,
  DatePicker,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import {
  useGenerateEventCertificateForTeachersV2Mutation,
  useRegenerateEventCertificateForTeachersV2Mutation,
} from "./CertificateGenerationApiSlice";
import ExcelTemplateDownload from "./ExcelTemplateDownload";

interface Participant {
  name: string;
  phone?: string;
  email?: string;
}

const CertificateGeneration: React.FC = () => {
  const [replaceExisting, setReplaceExisting] = useState(false);
  const [eventName, setEventName] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [eventDate, setEventDate] = useState<dayjs.Dayjs | null>(null);
  const [duration, setDuration] = useState("");
  const [participantsDetails, setParticipantsDetails] = useState<Participant[]>(
    []
  );
  const fileInputRef = useRef<HTMLInputElement>(null); 

  const [generateCertificate, { isLoading: isGenerating }] =
    useGenerateEventCertificateForTeachersV2Mutation();
  const [regenerateCertificate, { isLoading: isRegenerating }] =
    useRegenerateEventCertificateForTeachersV2Mutation();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    switch (field) {
      case "eventName":
        setEventName(value);
        break;
      case "certificateName":
        setCertificateName(value);
        break;
      case "duration":
        setDuration(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setEventDate(date);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (typeof data === "string" || data instanceof ArrayBuffer) {
        const workbook = XLSX.read(data, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        const parsedParticipants: Participant[] = jsonData
    .slice(1)
    .map((row: any) => {
      let email = row[1] ? row[1].toString().trim() : "";
      let phone = row[2] ? row[2].toString().trim() : "";
      const name = row[3] ? row[3].toString().trim() : "";

      // Email validation
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (email && !isValidEmail) {
        message.warning(`Invalid email address: ${email}`);
        email = ""; 
      }

      if (phone && !/^\d{10}$/.test(phone)) {
        message.warning(
          `Invalid phone number: ${phone}. Please ensure it is 10 digits long.`
        );
        phone = ""; 
      }

      // Create participant object dynamically
      const participant: Partial<Participant> = { name }; // Start with name
      if (email) {
        participant.email = email; // Add email if valid
      }
      if (phone) {
        participant.phone = phone; // Add phone if valid
      }

      // Check if at least one of email or phone is present
      if (participant.email || participant.phone) {
        return participant as Participant; // Return as Participant if valid
      } else {
        message.warning(`Missing valid email or phone for participant: ${name}`);
        return null; // Return null to filter out
      }
    })
    .filter(Boolean) as Participant[]; 

  setParticipantsDetails(parsedParticipants);
};
    };

    reader.readAsBinaryString(file);
  };
  console.log(participantsDetails);

  const handleGenerate = async () => {
    const formattedEventDate = eventDate?.format("YYYY-MM-DD") || "";

    const participantsData = {
      eventName,
      certificateName,
      eventDate: formattedEventDate,
      duration,
      participantsDetails,
    };

    try {
      // await generateCertificate(participantsData).unwrap();
      // message.success("Certificates generated successfully!");
      if (replaceExisting) {
        await regenerateCertificate(participantsData).unwrap();
        message.success("Certificates regenerated successfully!");
      } else {
        await generateCertificate(participantsData).unwrap();
        message.success("Certificates generated successfully!");
      }

      // Reset form fields after successful generation
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setEventName("");
      setCertificateName("");
      setEventDate(null);
      setDuration("");
      setParticipantsDetails([]);
      setReplaceExisting(false);
    } catch (error) {
      message.error("Failed to generate certificates.");
      console.error("Error generating certificates:", error);
    }
  };
  return (
    <div className="p-4">
      <Typography.Title level={2}>Certificate Generation</Typography.Title>

      <Form layout="vertical" className="mb-4">
        <Form.Item label="Event Name">
          <Input
            value={eventName}
            onChange={(e) => handleInputChange(e, "eventName")}
          />
        </Form.Item>
        <Form.Item label="Certificate Name">
          <Input
            value={certificateName}
            onChange={(e) => handleInputChange(e, "certificateName")}
          />
        </Form.Item>
        <Form.Item label="Event Date">
          <DatePicker value={eventDate} onChange={handleDateChange} format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item label="Duration">
          <Input
            value={duration}
            onChange={(e) => handleInputChange(e, "duration")}
          />
        </Form.Item>
        <ExcelTemplateDownload />
        {/* Use Ant Design Input with type="file" */}
        <Form.Item label="Upload Excel File">
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} ref={fileInputRef} />
        </Form.Item>
      </Form>

      <Typography.Title level={4} className="mt-4">
        Participants Details:
      </Typography.Title>
      <List
        bordered
        dataSource={participantsDetails}
        renderItem={(participant) => (
          <List.Item>
            <List.Item.Meta
              title={participant.name}
              description={`${participant?.phone}, ${participant?.email ? participant.email : ""}`}
            />
          </List.Item>
        )}
      />

      <Space className="mt-4">
        
        <Button
          type="primary"
          onClick={handleGenerate}
          loading={isGenerating}
          disabled={
            !eventName ||
            !certificateName ||
            !eventDate ||
            !duration ||
            participantsDetails.length === 0
          }
        >
          Generate Certificates
        </Button>
        <Checkbox
          checked={replaceExisting}
          onChange={(e) => setReplaceExisting(e.target.checked)}
        >
          Replace Existing Certificates
        </Checkbox>
      </Space>
    </div>
  );
};

export default CertificateGeneration;
