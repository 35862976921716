import { useEffect, useState } from "react";
import { ChapterDatass, ChapterFilters } from "../types";
import { useGetChaptersQuery } from "../ChapterApiSlice";
import { CurriculumBoard } from "../../EditTopics/EditTopicsApiSlice";

export const useChapterQuestions = (
  allConceptTags: any[],
  selectedFilter: ChapterFilters,
  curriculumBoard?: CurriculumBoard
) => {
  const [questions, setQuestions] = useState<ChapterDatass[]>([]);

  const { data: questionList, refetch } = useGetChaptersQuery(
    {
      topic: selectedFilter.topicId || null,
      subtopic: selectedFilter.subtopicId || null,
      curriculumBoard: curriculumBoard,
    },
    {
      skip: !selectedFilter?.topicId || !selectedFilter?.subtopicId,
    }
  );

  useEffect(() => {
    if (questionList) {
      const updatedQuestions = questionList.map((chapter) => ({
        ...chapter,
        slides: chapter.slides?.map((slide) => ({
          ...slide,
          questionIds: slide.questionIds?.map((question) => {
            const conceptTagIds = question.conceptTags || [];
            const mappedConceptTags = allConceptTags
              ? conceptTagIds
                  .map((tagId) => allConceptTags.find((tag) => tag.id === tagId))
                  .filter((tag) => tag !== undefined)
              : [];

            return {
              ...question,
              allConceptTags: allConceptTags || [],
              concept: mappedConceptTags.map((tag) => tag?.name || ""),
              conceptTags: conceptTagIds,
            };
          }),
        })),
      }));
      setQuestions(updatedQuestions);
    }
  }, [questionList, allConceptTags]);

  return { questions, setQuestions, refetch };
};
