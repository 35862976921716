import { Col, Collapse, List, message, Row, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { useState } from "react";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";

const { Panel } = Collapse;

interface QuestionListPreviewProps {
  questions: IMCQQuestion[];
  onEdit?: () => void;
  onDelete?: (id?: string) => void;
  setEditingQuestion?: any;
  setSelectedSlideForQuestions?: any;
  slideId?: string;
  disableDelete?: boolean;
}

const QuestionListChapters: React.FC<QuestionListPreviewProps> = ({
  questions,
  onEdit,
  onDelete,
  setEditingQuestion,
  disableDelete,
}) => {
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const renderListHeader = () => {
    const questionCount = questions.length;
    const headerString = `Total Questions: ${questionCount}`;
    return (
      <Row justify="space-between" align={"middle"}>
        <Col>{headerString}</Col>
      </Row>
    );
  };

  const handlePanelChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };

  return (
    <List
      header={renderListHeader()}
      bordered
      dataSource={questions}
      renderItem={(question: IMCQQuestion) => (
        <List.Item>
          <Collapse
            style={{ width: "100%" }}
            accordion
            onChange={handlePanelChange}
            activeKey={activeKey}
          >
            <Panel
              header={
                <div className="flex w-full justify-between items-center">
                  <span>{question.en_question[0]?.value}</span>
                  <Space direction="horizontal" size={"large"}>
                    {onEdit && (
                      <EditOutlined
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering Collapse toggle
                          setEditingQuestion(question);
                          onEdit();
                        }}
                      />
                    )}
                    {/* {onDelete && (
                      <DeleteOutlined
                        disabled={disableDelete}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering Collapse toggle
                          
                          onDelete(question._id);
                          
                        }}
                      />
                    )} */}
                    {onDelete && (
                      <span
                        onClick={(e) => {
                          if (!disableDelete) {
                            // Only proceed if not disabled
                            e.stopPropagation();
                            const questionId = question._id || question.id;
                            if (questionId) {
                              onDelete(questionId); // Pass the correct id to onDelete function
                            } else {
                              message.error(
                                "No valid ID found for the question."
                              );
                            }
                          }
                        }}
                        className={`cursor-pointer ${
                          disableDelete ? "text-gray-300" : "text-red-500"
                        }`} // Conditional styling
                      >
                        <DeleteOutlined />
                      </span>
                    )}
                  </Space>
                </div>
              }
              key={question.id || `question-${question.en_question[0]?.value}`}
            >
              <McqViewer isSelect={true} question={question} />
            </Panel>
          </Collapse>
        </List.Item>
      )}
    />
  );
};

export default QuestionListChapters;
