import React from 'react';
import { Download } from 'lucide-react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';

const ExcelTemplateDownload = () => {
  const handleDownloadTemplate = () => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['Serial No', 'Email', 'Phone', 'Name'], // Headers
      ['1', 'john@example.com', '9876543210', 'John Doe'],
      ['2', 'jane@example.com', '9876543211', 'Jane Smith'],
    ]);

    // Set column widths
    const colWidths = [
      { wch: 10 }, // Serial No
      { wch: 25 }, // Email
      { wch: 15 }, // Phone
      { wch: 25 }, // Name
    ];
    ws['!cols'] = colWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Template');

    // Generate and download the file
    XLSX.writeFile(wb, 'certificate_participants_template.xlsx');
  };

  return (
    <Button 
      onClick={handleDownloadTemplate}
      className="mb-4 flex items-center gap-2"
    >
      <Download className="w-4 h-4" />
      Sample Excel Template
    </Button>
  );
};

export default ExcelTemplateDownload;