import { Result, Button } from 'antd';
import { FilterOutlined, BookOutlined, ReadOutlined, ArrowUpOutlined } from '@ant-design/icons';
 
export const EmptyStateContent = () => (
  <Result
    icon={
      <div className="flex flex-col items-center">
        <div className="relative">
          <div className="bg-blue-50 w-24 h-24 rounded-full flex items-center justify-center">
            <FilterOutlined className="text-blue-500 text-4xl" />
          </div>
          <div className="absolute -right-4 -top-2">
            <div className="bg-green-50 w-12 h-12 rounded-full flex items-center justify-center">
              <BookOutlined className="text-green-500 text-xl" />
            </div>
          </div>
          <div className="absolute -left-2 -bottom-1">
            <div className="bg-purple-50 w-10 h-10 rounded-full flex items-center justify-center">
              <ReadOutlined className="text-purple-500 text-lg" />
            </div>
          </div>
        </div>
      </div>
    }
    title={
      <div className="text-xl font-semibold mt-6">
        Select Filters to View Chapters/Questions
      </div>
    }
    subTitle={
      <div className="flex flex-col items-center space-y-4 mt-4">
        <div className="text-gray-500 max-w-md text-center">
          Please select the required filters above to view and manage your chapters:
        </div>
        <div className="flex space-x-8 mt-4 text-gray-600">
          <div className="flex flex-col items-center">
            <div className="bg-gray-50 w-12 h-12 rounded-full flex items-center justify-center mb-2">
              <span className="text-lg font-semibold">1</span>
            </div>
            <span className="text-sm">Select Class</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gray-50 w-12 h-12 rounded-full flex items-center justify-center mb-2">
              <span className="text-lg font-semibold">2</span>
            </div>
            <span className="text-sm">Choose Subject</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gray-50 w-12 h-12 rounded-full flex items-center justify-center mb-2">
              <span className="text-lg font-semibold">3</span>
            </div>
            <span className="text-sm">Select Chapter</span>
          </div>
        </div>
      </div>
    }
    extra={
      <div className="flex items-center text-gray-500 mt-4">
        <ArrowUpOutlined className="animate-bounce mr-2" />
        Use the filters above to get started
      </div>
    }
    className="mt-8 bg-white rounded-lg  py-12"
  />
);

