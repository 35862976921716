export enum ConceptTitle {
  CRITICALITY = "Criticality Level",
  LEARNING_STYLE = "Learning Style Alignment",
  LEARNING_CURVE = "Learning Curve",
  COMPLEXITY = "Learning Complexity",
  TIME_TO_MASTER = "Time to Master",
  DEPENDENCIES = "Required Concepts",
  DEPENDENTS_COUNT = "Dependent Concepts Count",
  LIFE_RELEVANCE = "Real Life Impact",
  ACADEMIC_RELEVANCE = "Academic Impact",
  JEE_RELEVANCE = "JEE Importance",
  NEET_RELEVANCE = "NEET Importance",
  PRACTICAL_USES = "Practical Uses",
  RETENTION_IMPORTANCE = "Retention Importance",
  REVIEW_FREQUENCY = "Review Frequency"
}

export enum ConceptBenefitIcons {
  TIMER = "timer",
  BOOK = "book",
  STAR = "star",
  // Add other icons as needed
}

export enum CurriculumBoard {
  CBSE = "CBSE",
  ICSE = "ICSE",
  // Add other boards as needed
}

export interface IConceptBenefits {
  text: string;
  icon: ConceptBenefitIcons;
}

export interface IConceptTag {
  name: string;
  title: ConceptTitle;
  description: string;
  range: any; // The value will depend on the title selected
}

export interface ICreateConceptData {
  name: string;
  chapter: string;
  benefits?: IConceptBenefits[];
  meta?: Record<string, string>;
  curriculumBoard?: CurriculumBoard;
  conceptTags?: IConceptTag[];
}

// Range validation and helper functions
export const getTitleDescription = (title: ConceptTitle): string => {
  const descriptions: Record<ConceptTitle, string> = {
    [ConceptTitle.CRITICALITY]: "Importance for future learning. Low: Optional, Medium: Important, High: Critical",
    [ConceptTitle.LEARNING_STYLE]: "Alignment with different learning styles",
    [ConceptTitle.LEARNING_CURVE]: "Typical student learning trajectory",
    [ConceptTitle.COMPLEXITY]: "1: Very basic concepts, 5: Moderate difficulty requiring practice, 10: Complex concepts needing significant effort",
    [ConceptTitle.TIME_TO_MASTER]: "Estimated hours needed to achieve mastery",
    [ConceptTitle.DEPENDENCIES]: "List of concept IDs that must be learned first",
    [ConceptTitle.DEPENDENTS_COUNT]: "Number of concepts that require this as prerequisite",
    [ConceptTitle.LIFE_RELEVANCE]: "1: Purely academic, 2: Occasional use, 3: Regular applications, 4: Important life skill, 5: Essential daily skill",
    [ConceptTitle.ACADEMIC_RELEVANCE]: "1: Rarely used, 2: Occasionally helpful, 3: Important foundation, 4: Critical for subject, 5: Essential across subjects",
    [ConceptTitle.JEE_RELEVANCE]: "1: Not tested, 2: Rarely tested, 3: Sometimes tested, 4: Frequently tested, 5: High-priority topic",
    [ConceptTitle.NEET_RELEVANCE]: "1: Not tested, 2: Rarely tested, 3: Sometimes tested, 4: Frequently tested, 5: High-priority topic",
    [ConceptTitle.PRACTICAL_USES]: "List of real-world applications and use cases",
    [ConceptTitle.RETENTION_IMPORTANCE]: "1: Temporary understanding sufficient, 2: Basic recall needed, 3: Solid understanding required, 4: Deep mastery important, 5: Permanent mastery essential",
    [ConceptTitle.REVIEW_FREQUENCY]: "Recommended interval between reviews in days"
  };
  return descriptions[title];
};

export const getRangeOptions = (title: ConceptTitle): any => {
  switch (title) {
    case ConceptTitle.CRITICALITY:
      return ['low', 'medium', 'high'];
    case ConceptTitle.LEARNING_STYLE:
      return ['visual', 'textual', 'kinesthetic'];
    case ConceptTitle.LEARNING_CURVE:
      return ['steep', 'gradual', 'plateau'];
    case ConceptTitle.COMPLEXITY:
      return { min: 1, max: 10 };
    case ConceptTitle.TIME_TO_MASTER:
      return 'number (hours)';
    case ConceptTitle.DEPENDENCIES:
      return 'array of concept.id';
    case ConceptTitle.DEPENDENTS_COUNT:
      return 'number';
    case ConceptTitle.LIFE_RELEVANCE:
    case ConceptTitle.ACADEMIC_RELEVANCE:
    case ConceptTitle.JEE_RELEVANCE:
    case ConceptTitle.NEET_RELEVANCE:
    case ConceptTitle.RETENTION_IMPORTANCE:
      return { min: 1, max: 5 };
    case ConceptTitle.PRACTICAL_USES:
      return 'array of strings';
    case ConceptTitle.REVIEW_FREQUENCY:
      return 'number (days)';
  }
};