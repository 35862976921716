import React, { useState } from "react";
import {
  DatePicker,
  Space,
  Typography,
  Table,
  Card,
  Select,
  Input,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  useGetCompetitionMetricsQuery,
  useGetSchoolsQuery,
} from "../../quiz/CompetitionApiSlice";
import CompetitionSelect from "../../quiz/competitionSelect";
import MetricsEmptyState from "./MetricsEmptyState";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface CompetitionMetric {
  competitionName: string;
  averageScore: number;
  averageTime: number;
  uniqueUsers: number;
  activityCount: number;
  gradeWiseCount: { [key: string]: number };
  typeWiseCount: { [key: string]: number };
  totalSessions: number;
  completedSessions: number;
  completionRate: number;
  activityData: any[]; // Assuming activityData is an array of objects
  studentData: any[]; // Assuming studentData is an array of objects
  userData: any[]; // Assuming userData is an array of objects
}
interface DataType {
  name: string;
}

interface Props {
  schoolsData: DataType[];
}

const CompetitionMetrics: React.FC = () => {
  const [dates, setDates] = useState<[string, string] | null>(null);
  const [competitionId, setCompetitionId] = useState("");
  const [selectedData, setSelectedData] = useState("activityData");
  const [searchText, setSearchText] = useState("");
  const [filteredGrade, setFilteredGrade] = useState<number | undefined>();

  const {
    data: metricsData,
    isLoading,
    isError,
  } = useGetCompetitionMetricsQuery(
    {
      fromDate: dates?.[0] || "",
      toDate: dates?.[1] || "",
      competitionId,
    },
    { skip: !dates || !competitionId }
  );
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    isError: isSchoolsError,
  } = useGetSchoolsQuery({});

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDates(dateStrings);
  };

  const handleDataChange = (value: string) => {
    setSelectedData(value);
  };

  const handleGradeChange = (value: number | undefined) => {
    setFilteredGrade(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Competition Name",
      dataIndex: "competitionName",
      key: "competitionName",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.competitionName.localeCompare(b.competitionName),
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.averageScore - b.averageScore,
      render: (value: number) => value?.toFixed(2),
    },
    {
      title: "Average Time (seconds)",
      dataIndex: "averageTime",
      key: "averageTime",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.averageTime - b.averageTime,
      render: (value: number) => value?.toFixed(2),
    },
    {
      title: "Unique Users",
      dataIndex: "uniqueUsers",
      key: "uniqueUsers",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.uniqueUsers - b.uniqueUsers,
    },
    {
      title: "Activity Count",
      dataIndex: "activityCount",
      key: "activityCount",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.activityCount - b.activityCount,
    },
    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      key: "totalSessions",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.totalSessions - b.totalSessions,
    },
    {
      title: "Completed Sessions",
      dataIndex: "completedSessions",
      key: "completedSessions",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.completedSessions - b.completedSessions,
    },
    {
      title: "Completion Rate (%)",
      dataIndex: "completionRate",
      key: "completionRate",
      sorter: (a: CompetitionMetric, b: CompetitionMetric) =>
        a.completionRate - b.completionRate,
      render: (value: number) => value?.toFixed(2),
    },
    {
      title: "Grade-wise Count",
      dataIndex: "gradeWiseCount",
      key: "gradeWiseCount",
      render: (gradeWiseCount: { [key: string]: number }) => (
        <ul>
          {Object.entries(gradeWiseCount).map(([grade, count]) => (
            <li key={grade}>
              {grade}: {count}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Type-wise Count",
      dataIndex: "typeWiseCount",
      key: "typeWiseCount",
      render: (typeWiseCount: { [key: string]: number }) => (
        <ul>
          {Object.entries(typeWiseCount).map(([type, count]) => (
            <li key={type}>
              {type}: {count}
            </li>
          ))}
        </ul>
      ),
    },
  ];

  const activityDataColumns = [
    {
      title: "Activity Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Activity Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record.title
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      render: (value: number) => (value ? value.toFixed(2) : "-"),
    },
    {
      title: "Average Time (seconds)",
      dataIndex: "averageTime",
      key: "averageTime",
      render: (value: number) => (value ? value.toFixed(2) : "-"),
    },
    {
      title: "Unique Users",
      dataIndex: "uniqueUsers",
    },
    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      // key: "totalSessions",
      // sorter: (a, b) => a.totalSessions - b.totalSessions,
    },
    {
      title: "Completed Sessions",
      dataIndex: "completedSessions",
      // key: "completedSessions",
      // sorter: (a, b) => a.completedSessions - b.completedSessions,
    },
    {
      title: "Completion Rate (%)",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (value: number) => (value ? value.toFixed(2) : "-"),
      // sorter: (a, b) => a.completionRate - b.completionRate,
      // render: (value: number) => value?.toFixed(2) || "-",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      filters: Array.from({ length: 12 }, (_, i) => ({
        text: i + 1,
        value: i + 1,
      })),
      onFilter: (value: any, record: any) => record.grade === value,
      // sorter: (a, b) => a.grade - b.grade,
    },
  ];

  const userDataColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
        clearFilters?: () => void;
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record.name
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
        clearFilters?: () => void;
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Phone"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record?.phone
          ?.toString()
          ?.toLowerCase()
          ?.includes((value as string).toLowerCase()),
    },
    {
      title: "School",
      dataIndex: "schoolId",
      key: "schoolId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
        clearFilters?: () => void;
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Select
            placeholder="Select Name"
            value={selectedKeys[0] as string}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            style={{ width: "100%", marginBottom: 8 }}
          >
            {schoolsData?.map((school: any) => (
              <Option key={school.name} value={school.name}>
                {school.name}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="Search School"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                setSelectedKeys([]);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      // onFilter: (value: string | number | boolean, record: DataType) =>
      //   record.name.toLowerCase().includes(value.toString().toLowerCase()),
      onFilter: (value: any, record: any) =>
        record?.schoolId
          ?.toString()
          ?.toLowerCase()
          ?.includes((value as string)?.toLowerCase()),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      filters: Array.from({ length: 12 }, (_, i) => ({
        text: i + 1,
        value: i + 1,
      })),
      onFilter: (value: any, record: any) => record.grade === value,
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      render: (value: number) => value?.toFixed(2) || "-",
    },
    {
      title: "Average Time (seconds)",
      dataIndex: "averageTime",
      key: "averageTime",
      render: (value: number) => value?.toFixed(2) || "-",
    },
    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      key: "totalSessions",
    },
    {
      title: "Completed Sessions",
      dataIndex: "completedSessions",
      key: "completedSessions",
    },
    {
      title: "Completion Rate (%)",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (value: number) => value?.toFixed(2) || "-",
    },
  ];

  const schoolDataColumns = [
    {
      title: "School",
      dataIndex: "schoolId",
      key: "schoolId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
        clearFilters?: () => void;
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            placeholder="Select Name"
            value={selectedKeys[0] as string}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            style={{ width: "100%", marginBottom: 8 }}
          >
            {schoolsData?.map((school: any) => (
              <Option key={school.name} value={school.name}>
                {school.name}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="Search School"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record?.schoolId
          ?.toString()
          ?.toLowerCase()
          ?.includes((value as string)?.toLowerCase()),
    },
    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      key: "totalSessions",
    },
    {
      title: "Completed Sessions",
      dataIndex: "completedSessions",
      key: "completedSessions",
    },
    {
      title: "Completion Rate (%)",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (value: number) => value?.toFixed(2) || "-",
    },
    {
      title: "Total Activity Count",
      dataIndex: "totalActivityCount",
      key: "totalActivityCount",
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      render: (value: number) => value?.toFixed(2) || "-",
    },
    {
      title: "Average Time (seconds)",
      dataIndex: "averageTime",
      key: "averageTime",
      render: (value: number) => value?.toFixed(2) || "-",
    },
  ];

  return (
    <div className="p-4">
      <Typography.Title level={2}>Competition Metrics</Typography.Title>
  
      <Space direction="horizontal" className="mb-4">
        <CompetitionSelect setCompetitionId={setCompetitionId} />
        <RangePicker onChange={handleDateChange} format="YYYY-MM-DD" />
        <Select
          value={selectedData}
          onChange={handleDataChange}
          style={{ width: 200 }}
        >
          <Option value="activityData">Activity Data</Option>
          <Option value="schoolData">School Data</Option>
          <Option value="userData">User Data</Option>
        </Select>
      </Space>
  
      {!competitionId || !dates ? (
        <MetricsEmptyState />
      ) : (
        <>
          {metricsData && (
            <Card className="mt-4">
              <Table
                columns={
                  selectedData === "activityData"
                    ? activityDataColumns
                    : selectedData === "schoolData"
                      ? schoolDataColumns
                      : userDataColumns
                }
                dataSource={metricsData[selectedData]}
                loading={isLoading}
                rowKey="id"
              />
            </Card>
          )}
  
          {metricsData && (
            <Card>
              <Table
                columns={columns}
                dataSource={[metricsData]}
                pagination={false}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
}

export default CompetitionMetrics;