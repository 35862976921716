import React, { useState, useRef, useEffect, useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "./CardWrapper";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogTrigger } from "../ui/Dialog";
import {
  useCreateActivityMutation,
  useGetCategoriesQuery,
  useGetSchoolsQuery,
  useGetTopicsQuery,
} from "./CompetitionApiSlice";
import { toast } from "sonner";
import { useGetQuestionsCatalogueQuery } from "../features/questions/QuestionsApiSlice";
import TopicSelector from "../components/dash-components/TopicSelector";
import { Select as AntSelect, DatePicker } from "antd";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { School } from "../features/schools/types";
import CompetitionDropdown from "./competitionDropdown";
import dayjs, { Dayjs } from "dayjs";
import CompetitionSelect from "./competitionSelect";
const { Option } = AntSelect;

// Updated Zod schema for validating the activity JSON
const ActivitySchema = z.object({
  worksheetToGrade: z.array(z.number().int().positive()).optional(),
  subject: z.string().min(1, "Subject is required").optional(),
  description: z.string().optional(),
  chapter: z.string().min(1, "Chapter is required").optional(),
  title: z.string().min(1, "Title is required"),
  startDate: z.string().min(1, "Start Date is required"),
  details: z.object({
    concepts: z.array(z.string()).optional(),
    bloomLevel: z.array(z.number().int().positive()).optional(), // Updated to array
    questionCount: z.number().int().positive(),
  }),
  showInCompetition: z.boolean(),
  schoolId: z.string().optional(), // Optional field
});

const AcademicWorksheetForm = () => {
  const [apiCompetitionId, setApiCompetitionId] = useState("");
  const [activityData, setActivityData] = useState<z.infer<
    typeof ActivitySchema
  > | null>(null);
  const [quizId, setQuizId] = useState("");
  const [competitionId, setCompetitionId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [worksheetToGrade, setWorksheetToGrade] = useState<string[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [topicId, setTopicId] = useState<string | null>(null);
  const [subtopicId, setSubtopicId] = useState<string | null>(null);
  const [allConceptTags, setAllConceptTags] = useState<any[]>([]);
  const [preselectedClass, setPreselectedClass] = useState<string | null>(null);
  const [preselectedTopic, setPreselectedTopic] = useState<string | null>(null);
  const [preselectedSubtopic, setPreselectedSubtopic] = useState<string | null>(
    null
  );
  const [selectedConceptTags, setSelectedConceptTags] = useState<string[]>([]);
  const [bloomLevel, setBloomLevel] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createActivity, { isLoading }] = useCreateActivityMutation();
  // const {
  //   data: allCategories,
  //   isLoading: isCategoriesLoading,
  //   isError: isCategoriesError,
  // } = useGetCategoriesQuery({});
  // const {
  //   data: topicsData,
  //   isLoading: isTopicsLoading,
  //   isError: isTopicsError,
  // } = useGetTopicsQuery(selectedCategoryId, { skip: !selectedCategoryId });

  const { data: classData, isSuccess: isClassDataSuccess } =
    useGetQuestionsCatalogueQuery({});
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    isError: isSchoolsError,
  } = useGetSchoolsQuery({});

  const form = useForm<z.infer<typeof ActivitySchema>>({
    resolver: zodResolver(ActivitySchema),
    defaultValues: {
      description: "",

      title: "",
      startDate: "",
      details: {
        questionCount: 10,
      },
      showInCompetition: false,
      schoolId: "",
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          const validationResult = ActivitySchema.safeParse(json);

          if (validationResult.success) {
            setActivityData(validationResult.data);
            form.reset(validationResult.data); // Populate form with JSON data
            toast.success("Activity JSON uploaded and validated successfully!");
          } else {
            toast.error(
              "Invalid JSON format. Please ensure it matches the required structure."
            );
            resetFileInput();
          }
        } catch (err) {
          toast.error(
            "Error parsing JSON file. Please ensure it's a valid JSON."
          );
          resetFileInput();
        }
      };
      reader.readAsText(file);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setActivityData(null);
  };

  const onSubmit = async (formData: z.infer<typeof ActivitySchema>) => {
    if (!formData) {
      toast.error("Please upload a valid activity JSON before submitting.");
      return;
    }
    const startDateUTC = formData.startDate
      ? dayjs(formData.startDate)
          .subtract(5, "hour")
          .subtract(30, "minute")
          .toISOString()
      : null;

    const data = {
      ...formData,
      subjectId: topicId, // Set topicId as subject
      chapterId: subtopicId, // Set subtopicId as chapter
      startDate: startDateUTC,
      details: {
        ...formData.details,
        concepts: selectedConceptTags, // Update concepts array
        bloomLevel: bloomLevel, // Update bloomLevel array
      },
      targetGradeLevels: worksheetToGrade.map(Number), // Convert string array to number array
      competitionId: apiCompetitionId,
    };

    try {
      const activityResponse: any = await createActivity({
        data,
      });

      console.log("Quiz Id", activityResponse.data.id);

      if (activityResponse.error) {
        const parsedMessage = JSON.parse(activityResponse.error.data.message);
        const errorMessage = parsedMessage.message;
        toast.error(errorMessage);

        toast.error("Failed to create Academic worksheet.");
        console.log("activity error", activityResponse.error.data.message);
        toast.error(activityResponse.error.data.message);
      } else {
        setQuizId(activityResponse.data.id);
        if (activityResponse.data.competitionId) {
          setCompetitionId(activityResponse.data.competitionId);
        }
        if (activityResponse.data.id) {
          setIsDialogOpen(true);
        }

        toast.success("Academic worksheet created successfully!");
        form.reset({
          worksheetToGrade: data.worksheetToGrade,

          description: data.description,

          title: data.title,

          details: {
            questionCount: data.details.questionCount,
          },
          showInCompetition: false,
          schoolId: data.schoolId,
        });
        resetFileInput();
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Error creating activity. Please try again.");
    }
  };

  const copyQuizId = () => {
    if (quizId) {
      navigator.clipboard
        .writeText(quizId)
        .then(() => {
          toast.success("QuizId copied to clipboard!");
        })
        .catch(() => {
          toast.error("Failed to copy QuizId.");
        });
    }
  };

  const handleDialogClose = () => {
    setQuizId(""); // Reset the quizId when the dialog is closed
    setIsDialogOpen(false); // Close the dialog
  };

  // useEffect(() => {
  //   if (isClassDataSuccess && classData && activityData) {
  //     // const firstQuestion = activity[0];
  //     const gradeName = `Class - ${activityData.contentFromGrade}`;
  //     const matchedClass = classData.find((c) => c.name === gradeName);
  //     if (matchedClass) {
  //       setPreselectedClass(matchedClass.id);
  //       const matchedTopic = matchedClass.topics.find(
  //         (t) => t.name === activityData.subject
  //       );

  //       if (matchedTopic) {
  //         setPreselectedTopic(matchedTopic.id);
  //         const matchedSubtopic = matchedTopic.subtopics.find(
  //           (s) => s.name === activityData.chapter
  //         );
  //         // console.log("anything",matchedSubtopic?.id)
  //         if (matchedSubtopic) {
  //           setPreselectedSubtopic(matchedSubtopic.id);
  //         }
  //       }
  //     }

  //     console.log(classData);
  //   }
  // }, [isClassDataSuccess, classData, activityData]);
  console.log(
    "preselected",
    preselectedClass,
    preselectedTopic,
    preselectedSubtopic
  );

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;
    const topicName = selection.topicName || undefined;
    console.log("topicName", topicName);

    setTopicId(topicId);
    setSubtopicId(subtopicId);
    setAllConceptTags(concepts);
  };

  const handleConceptChange = useCallback(
    (selectedConcepts: string[]) => {
      // Match the selected concept names with the allConceptTags to get their ids
      const matchedTags = allConceptTags
        ?.filter((tag) => selectedConcepts.includes(tag.name))
        .map((tag) => tag.id);

      // Update the question state with the matched concept ids
      setSelectedConceptTags(selectedConcepts);
    },
    [allConceptTags, setSelectedConceptTags]
  );
  console.log("helloooo", topicId, subtopicId, allConceptTags);
  console.log("bye", selectedConceptTags);

  const allGrades = Array.from({ length: 12 }, (_, i) => ({
    name: `${i + 1}`,
  }));

  const handleWorksheetToGradeChange = (values: string[]) => {
    setWorksheetToGrade(values);
  };
  console.log("worksheet", worksheetToGrade);

  const allBloomLevels = Array.from({ length: 6 }, (_, i) => ({
    name: `${i + 1}`,
  }));

  const handleBloomLevelChange = (values: string[]) => {
    setBloomLevel(values);
  };
  console.log("bloom", bloomLevel);
  return (
    <div>
      <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
        <DialogContent>
          <CardWrapper
            title="Quiz Created!"
            description="Your Quiz ID has been created successfully."
            showSocial={false}
            backButtonHref="/activities"
            backButtonLabel="Back to Activities"
          >
            <h2 className="text-lg font-semibold"></h2>
            <p>
              Your Competition ID is <strong>{competitionId}</strong>
            </p>
            <p>
              Your Quiz ID is: <strong>{quizId}</strong>
            </p>
            <Button onClick={copyQuizId} className="mt-4">
              Copy QuizId
            </Button>
          </CardWrapper>
        </DialogContent>
      </Dialog>

      <CardWrapper
        title="Create Academic Worksheet"
        description="Upload the activity JSON file below"
        showSocial={false}
        backButtonHref="/activities"
        backButtonLabel="Back to Activities"
        height="100%"
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            {/* <Button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="w-full"
            >
              Upload Activity JSON
            </Button> */}

            <div className="flex justify-between">
              {classData ? (
                <TopicSelector
                  classes={classData}
                  onChange={updateFilter}
                  preselectedClass={preselectedClass}
                  preselectedTopic={preselectedTopic}
                  preselectedSubtopic={preselectedSubtopic}
                />
              ) : (
                <span className="text-gray-500">Loading topics...</span> // Loading text
              )}
            </div>
            <AntSelect
              placeholder="Select Concepts(Optional)"
              mode="multiple"
              onChange={handleConceptChange}
              style={{ width: "100%" }}
              value={selectedConceptTags} // Set default values based on conceptTags
            >
              {allConceptTags?.map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </AntSelect>
            <AntSelect
              placeholder="Select Worksheet To Grade(Optional)"
              mode="multiple"
              onChange={handleWorksheetToGradeChange}
              style={{ width: "100%" }}
              value={worksheetToGrade} // Set default values based on conceptTags
            >
              {allGrades?.map((tag) => (
                <Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </AntSelect>
            <AntSelect
              placeholder="Select Bloom Level(Optional)"
              mode="multiple"
              onChange={handleBloomLevelChange}
              style={{ width: "100%" }}
              value={bloomLevel} // Set default values based on conceptTags
            >
              {allBloomLevels?.map((tag) => (
                <Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </AntSelect>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input placeholder="Description (optional)" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder="Title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date: Dayjs | null) =>
                        field.onChange(date ? date.toISOString() : "")
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="details.questionCount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Question Count</FormLabel>
                  <FormControl>
                    <Input
                      max={10}
                      type="number"
                      placeholder="Question Count"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="schoolId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>School</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select school(optional)" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {schoolsData?.map((school: School) => (
                        <SelectItem key={school.id} value={school.id}>
                          {school.name} - {school.city}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* <FormField
              control={form.control}
              name="showInCompetition"
              render={({ field }) => (
                <FormItem className="flex gap-2 ">
                  <FormLabel className="mb-0">Show in Competition</FormLabel>
                  <FormControl>
                    <Input
                      type="checkbox"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      className="h-5 w-5 translate-y-[-9px]"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
            <div>
              <CompetitionSelect setCompetitionId={setApiCompetitionId} />
            </div>

            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Form>
      </CardWrapper>
    </div>
  );
};

export default AcademicWorksheetForm;
