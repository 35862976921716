import { useEffect, useState } from "react";
import { useGetTopicwiseStatementsCountQuery } from "./CasesApiSlice";
import StatementCountTable from "./components/StatementsCountTable";
import { Button, Card, Space } from "antd";
import { PlusOutlined, ImportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import CaseStudyTemplateDownload from "./components/CaseStudyTemplateDownload";

export default function Statements() {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const {
    data: statementsBySubtopic,
    error,
    isLoading,
  } = useGetTopicwiseStatementsCountQuery();

  useEffect(() => {
    if (statementsBySubtopic) {
      setCount(
        statementsBySubtopic.reduce((acc, curr) => {
          return acc + curr.count;
        }, 0)
      );
    }
  }, [statementsBySubtopic]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const navigateToImport = () => {
    navigate("import");
  };

  const navigateToReviewer = () => {
    navigate("review");
  };

  return (
    <Card
      title={`Case based questions | ${count}`}
      extra={
        <Space>
          <Button type="primary" icon={<PlusOutlined />} disabled>
            Add New
          </Button>
          <Button
            type="dashed"
            icon={<ImportOutlined />}
            onClick={navigateToReviewer}
          >
            Review
          </Button>
          <Button
            type="primary"
            icon={<ImportOutlined />}
            onClick={navigateToImport}
          >
            Import
          </Button>
          <CaseStudyTemplateDownload
            filename="physics-case-study.json"
            buttonText="Download Case Study Sample"
          />
        </Space>
      }
    >
      <StatementCountTable data={statementsBySubtopic || []} />
    </Card>
  );
}
