import React, { useState, useEffect } from "react";
import { Button, Drawer, message, Space } from "antd";
import DOMPurify from "dompurify";
import styled from "styled-components";
import HtmlEditorWithPreview from "../../../components/sectionEditor/HtmlEditorWithPreview";
import { MathJaxContext } from "better-react-mathjax";
import HtmlViewer from "../../../components/htmlViewer";

interface StatementEditorProps {
  initialData: string | string[]; // Adjusted to handle both types
  onSave?: (content: string[]) => Promise<void>;
}

const ButtonContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 24px;
`;

const ContentDisplay = styled.div`
  padding: 24px;
  padding-top: 72px;
  margin-bottom: 24px;
  background-color: white;
  height: 100%;
  overflow: auto;
  img {
    max-width: 100%;
    max-height: 200px;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }
`;

const StatementEditor: React.FC<StatementEditorProps> = ({
  initialData,
  onSave,
}) => {
  const [decodedData, setDecodedData] = useState<string[]>([]);
  const [editedData, setEditedData] = useState<string[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentEditIndex, setCurrentEditIndex] = useState<number>(-1);

  useEffect(() => {
    const dataToSet = Array.isArray(initialData) ? initialData : [initialData]; // Ensure initialData is always an array
    setDecodedData(dataToSet);
    setEditedData(dataToSet); // Set both decodedData and editedData to be consistent
  }, [initialData]);

  const handleEditorChange = (content: string) => {
    const newEditedData = [...editedData];
    newEditedData[currentEditIndex] = content; // Update only the specific content being edited
    setEditedData(newEditedData); // Preserve other content and update the specific content
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const sanitizedContent = editedData.map((item) => DOMPurify.sanitize(item)); // Sanitize all content
      if (!onSave) return;
      await onSave(sanitizedContent); // Save sanitized content
      message.success("Content saved successfully");
      setDecodedData(sanitizedContent); // Update decodedData with sanitized content
      setIsDrawerVisible(false); // Close the drawer after saving
    } catch (error) {
      message.error("Failed to save content");
    } finally {
      setIsSaving(false);
    }
  };

  const openDrawer = (index: number) => {
    setCurrentEditIndex(index); // Set the index of the content being edited
    setIsDrawerVisible(true); // Open the drawer to edit content
  };

  return (
    <MathJaxContext>
      <div style={{ height: "100%", position: "relative" }}>
        {decodedData.map((item, index) => (
          <div key={index} style={{ position: "relative", marginBottom: "20px" }}>
            <HtmlViewer htmlContent={item} mobileView={true} />
            <ButtonContainer>
              <Button
                type="primary"
                onClick={() => openDrawer(index)}
                size="large"
              >
                Edit Content
              </Button>
            </ButtonContainer>
          </div>
        ))}
        <Drawer
          title={<div>Edit Content {currentEditIndex + 1}</div>}
          placement="right"
          width="80%"
          onClose={() => setIsDrawerVisible(false)}
          open={isDrawerVisible}
          extra={
            <Space>
              <Button onClick={() => setIsDrawerVisible(false)}>Cancel</Button>
              <Button type="primary" onClick={handleSave} loading={isSaving}>
                Save All
              </Button>
            </Space>
          }
          style={{ padding: "24px" }}
        >
          {currentEditIndex !== -1 && (
            <HtmlEditorWithPreview
              html={editedData[currentEditIndex]}
              onHtmlChange={handleEditorChange}
            />
          )}
        </Drawer>
      </div>
    </MathJaxContext>
  );
};

export default StatementEditor;
