import { Drawer, Button } from 'antd';
import { IMCQQuestion } from '../../../components/dash-components/Mcq/types';
import MCQEditor from '../../../components/dash-components/Mcq/McqEditor';
import FilteredQuestions from '../../questions/FilteredQuestions';
import { ChapterFilters } from '../types';
import { Dispatch, SetStateAction } from 'react';
import { CurriculumBoard } from '../../EditTopics/EditTopicsApiSlice';

interface QuestionEditorDrawerProps {
  open: boolean;
  onClose: () => void;
  question?: IMCQQuestion;
  onSave: (data: any) => void;
  uploadAudio: (formData: FormData) => Promise<string>;
  uploadImage: (formData: FormData) => Promise<string>;
}

export const QuestionEditorDrawer: React.FC<QuestionEditorDrawerProps> = ({
  open,
  onClose,
  question,
  onSave,
  uploadAudio,
  uploadImage,
}) => (
  <Drawer
    title="Edit Questions"
    open={open}
    width="90%"
    onClose={onClose}
    styles={{ header: { paddingBottom: 32 } }}
    destroyOnClose
  >
    <MCQEditor
      data={question}
      onSave={onSave}
      uploadAudio={uploadAudio}
      uploadImage={uploadImage}
    />
  </Drawer>
);


interface FilteredQuestionsProps {
    selectedClass?: string;
    selectedTopic?: string | null;
    selectedSubtopic?: string | null;
    isSelect?: boolean;
    selectedQuestions: IMCQQuestion[];
    setSelectedQuestions: Dispatch<SetStateAction<IMCQQuestion[]>>;
  }
  
 
  
  interface QuestionSelectorDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedQuestions: IMCQQuestion[];
    setSelectedQuestions: Dispatch<SetStateAction<IMCQQuestion[]>>;
    onSubmit: () => void;
    selectedClass?: string;
    selectedFilter: ChapterFilters;
    curriculumBoard: CurriculumBoard | "";
  }
  
  export const QuestionSelectorDrawer: React.FC<QuestionSelectorDrawerProps> = ({
    open,
    onClose,
    selectedQuestions,
    setSelectedQuestions,
    onSubmit,
    selectedClass,
    selectedFilter,
    curriculumBoard
  }) => (
    <Drawer
      title="Select Questions"
      placement="right"
      width={1300}
      onClose={onClose}
      open={open}
      extra={
        <Button
          type="primary"
          disabled={selectedQuestions.length === 0}
          onClick={onSubmit}
        >
          Submit
        </Button>
      }
      height={500}
    >
      <FilteredQuestions
        selectedClass={selectedClass}
        selectedTopic={selectedFilter?.topicId}
        selectedSubtopic={selectedFilter?.subtopicId}
        isSelect={true}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
        curriculumBoard={curriculumBoard}
        showClassData={false}
      />
    </Drawer>
  );
  