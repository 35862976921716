import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  TreeSelect,
  Button,
  Checkbox,
  Upload,
  message,
  DatePicker,
  Radio,
  Typography,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  useCreateCompetitionMutation,
  useGetClassSectionsMutation,
  useUpdateCompetitionMutation,
} from "./TournamentsApiSlice";
import { CompetitionRewardType } from "./CreateTournament";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { useGetSchoolsQuery } from "../schools/SchoolsApiSlice";
import { School } from "../schools/types";
import Cron from "react-js-cron";
import TopicSubtopicSelector from "./components/TopicSubtopicSelector";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const CreateOrEditTournament = ({
  competition,
  onSuccess,
}: {
  competition?: any;
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();
  const [cronExpression, setCronExpression] = useState<string>("* * * * * *");

  const [createCompetition] = useCreateCompetitionMutation();
  const [updateCompetition] = useUpdateCompetitionMutation();

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [TopicIds, setTopicIds] = useState<string[]>([]);
  const [selectedSubtopicIds, setSelectedSubtopicIds] = useState<string[]>([]);
  const [availableTopics, setAvailableTopics] = useState<any[]>([]);
  const [availableSubtopics, setAvailableSubtopics] = useState<any[]>([]);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState([]);
  const [availableClassSections, setAvailableClassSections] = useState<any[]>(
    []
  );
  const [rewardType, setRewardType] = useState<CompetitionRewardType | null>(
    null
  );
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [rewardMeta, setRewardMeta] = useState([
    { title: "", description: "" },
  ]);
  const [coverImageUrls, setCoverImageUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedClassSectionIds, setSelectedClassSectionIds] = useState([]);
  const [availableUsers, setAvailableUsers] = useState<any[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isParticipantOpen, setIsParticipantOpen] = useState(false);
  // const { data: classData, isLoading: isClassDataLoading } =
  //   useGetQuestionsCatalogueQuery({});
  const {
    data: classData,
    isSuccess: isClassDataSuccess,
    isFetching: isClassFetching,
    isLoading: isClassDataLoading,
  } = useGetQuestionsCatalogueQuery({});
  const [uploadAsset] = useUploadImageMutation();
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    isError: isSchoolsError,
  } = useGetSchoolsQuery();
  const [
    getClassSections,
    { isLoading: isClassSectionsLoading, isError: isClassSectionsError },
  ] = useGetClassSectionsMutation();

  // Pre-populate form and states when competition data is provided
  useEffect(() => {
    if (competition) {
      form.setFieldsValue({
        title: competition.title,
        subtitle: competition.subtitle,
        description: competition.description,
        type: competition.type,
        dateRange:
          competition.startDate && competition.endDate
            ? [dayjs(competition.startDate), dayjs(competition.endDate)]
            : null,
        // categoryIds: competition.competitionScope?.categoryIds,
        topicIds: competition.competitionScope?.topicIds,
        subtopicIds: competition.competitionScope?.subtopicIds,
        open: competition.competitionScope?.open,
        schoolIds: competition.participants?.schoolIds,
        gradeLevels: competition.participants?.gradeLevels,
        classSectionIds: competition.participants?.classSectionIds,
        userIds: competition.participants?.userIds,
        rewardType: competition.rewardType,
        leaderboardType: competition.leaderboardType,
      });
      setSelectedCategoryIds(competition.competitionScope?.categoryIds || []);
      setTopicIds(competition.competitionScope?.topicIds);
      setSelectedSubtopicIds(competition.competitionScope?.subtopicIds || []);
      setCronExpression(competition.scheduleCronExpression || "* * * * * *");
      setRewardMeta(competition.rewardMeta || [{ title: "", description: "" }]);
      setCoverImageUrls(competition.coverImages || []);
      setFileList(
        (competition.coverImages || []).map((url: string, index: number) => ({
          uid: `${index}`,
          name: `Cover Image ${index + 1}`,
          url,
          status: "done",
        }))
      );
      setIsParticipantOpen(competition.participants?.isOpen || false);
    }
  }, [competition, form]);
  //   useEffect(() => {
  //     if (competition) {
  //       setSelectedCategoryIds(competition.competitionScope?.categoryIds || []);
  //       setSelectedTopicIds(competition.competitionScope?.topicIds || []);
  //       setSelectedSubtopicIds(competition.competitionScope?.subtopicIds || []);
  //     }
  //   }, [competition]);
  useEffect(() => {
    if (competition) {
      setSelectedCategoryIds(competition.competitionScope?.categoryIds || []);
      setTopicIds(competition.competitionScope?.topicIds || []);
      setSelectedSubtopicIds(competition.competitionScope?.subtopicIds || []);
    }
  }, [competition]);
  console.log("selectedCategoryIds", selectedCategoryIds);
  console.log(
    "selectedTopicIds",
    TopicIds,
    competition.competitionScope?.topicIds
  );
  console.log("selectedSubtopicIds", selectedSubtopicIds);
  const handleAddReward = () => {
    setRewardMeta([...rewardMeta, { title: "", description: "" }]);
  };

  const getFormattedSubtopicTreeData = () => {
    return selectedCategoryIds.map((categoryId) => {
      const category = classData?.find((cat) => cat.id === categoryId);

      // Filter topics to only show selected ones
      const filteredTopics = category?.topics?.filter((topic) =>
        TopicIds.includes(topic.id)
      );

      return {
        title: category?.name,
        value: categoryId,
        key: categoryId,
        selectable: false,
        children: filteredTopics?.map((topic) => ({
          title: topic.name,
          value: topic.id,
          key: topic.id,
          selectable: false,
          children: topic.subtopics?.map((subtopic) => ({
            title: subtopic.name,
            value: subtopic.id,
            key: subtopic.id,
            selectable: true,
          })),
        })),
      };
    });
  };

  const handleRewardChange = (index: number, field: string, value: any) => {
    const updatedRewards = [...rewardMeta];
    updatedRewards[index] = { ...updatedRewards[index], [field]: value };
    setRewardMeta(updatedRewards);
  };

  const handleRemoveReward = (index: number) => {
    setRewardMeta(rewardMeta.filter((_, i) => i !== index));
  };

  const handleImageUpload = async ({ file, onSuccess, onError }: any) => {
    // Replace with actual upload logic
    try {
      const imageUrl = "uploaded_image_url"; // Replace with the API call result
      setCoverImageUrls((prevUrls) => [...prevUrls, imageUrl]);
      setFileList((prevList) => [
        ...prevList,
        {
          uid: file.uid,
          name: file.name,
          url: imageUrl,
          status: "done",
        },
      ]);
      message.success("Image uploaded successfully!");
      onSuccess("ok");
    } catch (error) {
      message.error("Image upload failed.");
      onError(error);
    }
  };

  const handleTopicChange = (value: string[]) => {
    setTopicIds(value);
    setSelectedSubtopicIds([]);
    form.setFieldsValue({ subtopicIds: [] });
  };

  const handleSubtopicChange = (value: string[]) => {
    setSelectedSubtopicIds(value);
  };
  useEffect(() => {
    const fetchClassSections = async () => {
      if (selectedCategoryIds.length) {
        try {
          const data = await getClassSections({
            schools: selectedSchoolIds,
            categoryIds: selectedCategoryIds,
          }).unwrap();
          // setClassSectionsData(data);
          console.log("data", data);
          setAvailableClassSections(data);
        } catch (error) {
          console.error("Error fetching class sections:", error);
          message.error("Failed to load class sections.");
        }
      } else {
        setAvailableClassSections([]);
        // setClassSectionsData([]); // Reset if dependencies change
      }
    };

    fetchClassSections();
  }, [selectedSchoolIds, selectedCategoryIds, getClassSections]);

  console.log("classSections", availableClassSections);
  useEffect(() => {
    if (selectedCategoryIds.length > 0) {
      const topics = selectedCategoryIds.flatMap((categoryId) => {
        const category = classData?.find(
          (classItem) => classItem.id === categoryId
        );
        return category ? category.topics : [];
      });
      setAvailableTopics(topics as any);
      setTopicIds([]);
      setAvailableSubtopics([]);
      setSelectedSubtopicIds([]);
    }
  }, [selectedCategoryIds, classData]);

  useEffect(() => {
    if (TopicIds.length > 0) {
      const subtopics = TopicIds.flatMap((topicId) => {
        const topic: any = availableTopics.find(
          (topic: any) => topic.id === topicId
        );
        return topic ? topic.subtopics : [];
      });
      setAvailableSubtopics(subtopics as any);
      setSelectedSubtopicIds([]);
    }
  }, [
    TopicIds,
    availableTopics,
    setAvailableSubtopics,
    setSelectedSubtopicIds,
  ]);

  const onFinish = async (values: any) => {
    const { dateRange, ...restValues } = values;
    const formattedStartDate = dateRange
      ? dateRange[0].format("YYYY-MM-DD")
      : null;
    const formattedEndDate = dateRange
      ? dateRange[1].format("YYYY-MM-DD")
      : null;

    const competitionData = {
      ...restValues,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      scheduleCronExpression: cronExpression,
      rewardMeta,
      coverImages: coverImageUrls,
      participants: {
        ...restValues.participants,
        isOpen: isParticipantOpen,
      },
      competitionScope: {
        ...restValues.competitionScope,
        categoryIds: selectedCategoryIds,
        topicIds: restValues.topicIds,
        subtopicIds: restValues.subtopicIds,
        open:restValues.open,
      }
    };

    try {
      if (competition) {
        await updateCompetition({
          competitionId: competition.id,
          competitionData,
        }).unwrap();
        message.success("Competition updated successfully!");
      } else {
        await createCompetition(competitionData).unwrap();
        message.success("Competition created successfully!");
      }
      onSuccess();
    } catch (error) {
      message.error(
        `Failed to ${competition ? "update" : "create"} competition.`
      );
      console.error(error);
    }
  };

  return (
    <div>
      {isClassFetching ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Form
          form={form}
          name="create-or-edit-tournament"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Enter tournament title" />
          </Form.Item>

          {/* Subtitle */}
          <Form.Item
            label="Subtitle"
            name="subtitle"
            rules={[{ required: true, message: "Please input the subtitle!" }]}
          >
            <Input placeholder="Enter tournament subtitle" />
          </Form.Item>

          {/* Description */}
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Enter tournament description" />
          </Form.Item>

          <Form.Item label="Type" name="type">
            <Select placeholder="Select tournament type">
              <Select.Option value="competition">Competition</Select.Option>
              <Select.Option disabled value="challenge">
                Challenge
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Tournament Dates" name="dateRange">
            <RangePicker
              format="YYYY-MM-DD"
              onChange={(_, dateStrings) => {
                const [start, end] = dateStrings;
                setStartDate(start);
                setEndDate(end);
                form.setFieldsValue({ startDate: start, endDate: end });
              }}
              value={
                startDate && endDate
                  ? [
                      dayjs(startDate, "YYYY-MM-DD"),
                      dayjs(endDate, "YYYY-MM-DD"),
                    ]
                  : undefined
              }
            />
          </Form.Item>

          <Title level={4}>Competition Scope</Title>
          <div className="mb-6 ml-5 ">
            <Select
              mode="multiple"
              placeholder="Select Categories"
              onChange={(value) => {
                setSelectedCategoryIds(value);
                setTopicIds([]); // Reset dependent fields
                setSelectedSubtopicIds([]);
              }}
              loading={isClassDataLoading}
              value={selectedCategoryIds} // Controlled value
            >
              {classData?.map((classItem) => (
                <Select.Option key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </Select.Option>
              ))}
            </Select>
            {/* </Form.Item> */}

            <TopicSubtopicSelector
              classData={classData}
              competition={competition}
              form={form}
              selectedCategoryIds={selectedCategoryIds}
              // selectedTopicIds={TopicIds}
              // selectedSubtopicIds={selectedSubtopicIds}
              setTopicIds={setTopicIds}
              setSubtopicIds={setSelectedSubtopicIds}
              setSelectedCategoryIds={setSelectedCategoryIds}
            />

            {/* Open/Closed Selection (using Radio.Group) */}
            <Form.Item label="Open/Close" name="open">
              <Radio.Group>
                <Radio value={true}>Open</Radio>
                <Radio value={false}>Close</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Title level={4}>Participants</Title>
          <div className="mb-6 ml-5">
            <Form.Item label="Schools" name="schoolIds">
              <Select
                mode="multiple"
                placeholder="Select Schools"
                loading={isSchoolsLoading}
                onChange={(value) => setSelectedSchoolIds(value)}
                disabled={isSchoolsError}
              >
                {schoolsData?.map((school: School) => (
                  <Select.Option key={school.id} value={school.id}>
                    {school.name} - {school.city}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Grade Levels Multi-Select */}
            <Form.Item label="Grade Levels" name="gradeLevels">
              <Select
                mode="multiple"
                placeholder="Select Grade Levels"
                onChange={(value) => setSelectedGradeLevels(value)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
                  <Select.Option key={grade} value={grade}>
                    {grade}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Class Section IDs Multi-Select */}
            <Form.Item
              label="Class Sections (need to select categories and schools)"
              name="classSectionIds"
            >
              <Select
                disabled={
                  selectedCategoryIds.length === 0 ||
                  selectedSchoolIds.length === 0
                }
                mode="multiple"
                placeholder="Select Class Sections"
                onChange={(value) => setSelectedClassSectionIds(value)}
              >
                {/* Assuming class section options are available in some state or fetched data */}
                {availableClassSections?.map((section) => (
                  <Select.Option key={section.id} value={section.id}>
                    {section.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* User IDs Multi-Select */}
            <Form.Item label="User IDs" name="userIds">
              <Select
                disabled
                mode="multiple"
                placeholder="Select Users"
                onChange={(value) => setSelectedUserIds(value)}
              >
                {/* Assuming user options are available in some state or fetched data */}
                {availableUsers?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name} ({user.email})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Participants" name="participants">
              {/* Checkbox for isOpen */}
              <Checkbox
                checked={isParticipantOpen}
                onChange={(e) => setIsParticipantOpen(e.target.checked)}
              >
                Open for Participants
              </Checkbox>

              {/* ... other participant-related fields (if any) */}
            </Form.Item>
          </div>

          <Form.Item label="Participant Schools" name="participantSchools">
            <Select
              mode="multiple"
              placeholder="Select participant schools"
              loading={isSchoolsLoading}
              // onChange={(value) => setSelectedSchoolIds(value)}
              disabled={isSchoolsError}
            >
              {schoolsData?.map((school: School) => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name} - {school.city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="coverImages" label="Cover Images">
            <Upload
              accept="image/*"
              listType="picture"
              customRequest={handleImageUpload}
              fileList={fileList} // Use fileList to display uploaded images
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                setCoverImageUrls((prevUrls) =>
                  prevUrls.filter((url) => url !== file.url)
                );
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          {/* Leaderboard Type (using Select) */}
          <Form.Item label="Leaderboard Type" name="leaderboardType">
            <Select placeholder="Select leaderboard type">
              <Select.Option value="score">Score</Select.Option>
              <Select.Option value="completion">Completion</Select.Option>
              <Select.Option value="gems">Gems</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Schedule Cron Expression">
            <Cron
              value={cronExpression}
              setValue={setCronExpression}
              clearButton={true}
              // clearButtonText="Clear"
            />
          </Form.Item>
          {/* Reward Type Field */}
          <Form.Item label="Reward Type" name="rewardType">
            <Select
              placeholder="Select Reward Type"
              value={rewardType}
              onChange={(value) => setRewardType(value)}
              style={{ width: "200px" }}
            >
              <Select.Option value={CompetitionRewardType.Cash}>
                Cash
              </Select.Option>
              <Select.Option value={CompetitionRewardType.Voucher}>
                Voucher
              </Select.Option>
            </Select>
          </Form.Item>

          {/* Rewards Meta Field */}
          <Form.Item label="Rewards">
            {rewardMeta.map((reward, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                {/* Title Field */}
                <Input
                  placeholder="Title"
                  value={reward.title}
                  onChange={(e) =>
                    handleRewardChange(index, "title", e.target.value)
                  }
                  style={{ width: "200px" }}
                />

                {/* Description Field */}
                <Input
                  placeholder="Description"
                  value={reward.description}
                  onChange={(e) =>
                    handleRewardChange(index, "description", e.target.value)
                  }
                  style={{ width: "200px" }}
                />

                {/* Remove Button */}
                {rewardMeta.length > 1 && (
                  <Button
                    type="dashed"
                    onClick={() => handleRemoveReward(index)}
                    icon={<DeleteOutlined />}
                  />
                )}
              </div>
            ))}

            {/* Add Reward Button */}
            <Button
              type="dashed"
              onClick={handleAddReward}
              icon={<PlusCircleOutlined />}
            >
              Add Reward
            </Button>
          </Form.Item>
          {/* Form fields remain the same */}
          {/* Replace "Create" button with dynamic text */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {competition ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default CreateOrEditTournament;

{
  /* Topic Tree-Select */
}
{
  /* <Form.Item label="Topics" name="topicIds">
          <TreeSelect
            disabled={selectedCategoryIds.length === 0}
            treeData={selectedCategoryIds.map((categoryId) => {
              const category = classData?.find((cat) => cat.id === categoryId);
              return {
                title: category?.name,
                value: categoryId,
                key: categoryId,
                selectable: false,
                children: category?.topics.map((topic: any) => ({
                  title: topic.name,
                  value: topic.id,
                  key: topic.id,
                  selectable: true,
                })),
              };
            })}
            value={TopicIds}
            onChange={handleTopicChange}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            placeholder="Select Topics"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label="Subtopics" name="subtopicIds">
          <TreeSelect
            disabled={TopicIds.length === 0}
            treeData={selectedCategoryIds.map((categoryId) => {
              // Find the category data
              const category = classData?.find((cat) => cat.id === categoryId);

              // Filter topics to only show selected ones
              const filteredTopics = category?.topics?.filter((topic: any) =>
                TopicIds.includes(topic.id)
              );

              return {
                title: category?.name,
                value: categoryId,
                key: categoryId,
                selectable: false,
                children: filteredTopics?.map((topic: any) => ({
                  title: topic.name,
                  value: topic.id,
                  key: topic.id,
                  selectable: false,
                  children: topic.subtopics?.map((subtopic: any) => ({
                    title: subtopic.name,
                    value: subtopic.id,
                    key: subtopic.id,
                    selectable: true,
                  })),
                })),
              };
            })}
            value={selectedSubtopicIds}
            onChange={handleSubtopicChange}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            placeholder="Select Subtopics"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Subtopics" name="subtopicIds">
        <TreeSelect
          disabled={TopicIds.length === 0}
          treeData={getFormattedSubtopicTreeData()}
          value={selectedSubtopicIds}
          onChange={handleSubtopicChange}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          placeholder="Select Subtopics"
          style={{ width: "100%" }}
        />
        </Form.Item> */
}
