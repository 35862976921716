import React, { useState } from "react";
import { Calendar, Trophy, Users, Edit } from "lucide-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import CreateOrEditTournament from "./CreateOrEditTounament";
import { Modal } from "antd";

interface Competition {
  id: string;
  title: string;
  subtitle: string;
  description?: string;
  startDate: string;
  endDate: string;
  leaderboardType: string;
  type: string;
  coverImages: string[];
}

interface TournamentCardProps {
  competition: Competition;
  refreshList?: () => void; // Callback to refresh the competition list
}

const TournamentCard: React.FC<TournamentCardProps> = ({
  competition,
  refreshList,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering parent click events
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        key={competition.id}
        className="overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        // onClick={() => (window.location.href = `showTournaments/${competition.id}`)}
      >
        <div className="relative h-48 w-full">
          <img
            src={
              competition.coverImages[0] ||
              "https://s3.ap-south-1.amazonaws.com/bheem.images/misc/c-2.png"
            }
            alt={competition.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-4 right-4 bg-white/90 px-3 py-1 rounded-full">
            <span className="text-sm font-medium">{competition.type}</span>
          </div>
        </div>

        <CardHeader className="space-y-1">
          <CardTitle className="text-xl line-clamp-1">
            {competition.title}
          </CardTitle>
          <p className="text-sm text-gray-500 line-clamp-1">
            {competition.subtitle}
          </p>
        </CardHeader>

        <CardContent>
          <div className="flex flex-col space-y-2 text-sm">
            <div className="flex items-center space-x-2">
              <Calendar className="h-4 w-4" />
              <span>
                {new Date(competition.startDate).toLocaleDateString()} -{" "}
                {new Date(competition.endDate).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Trophy className="h-4 w-4" />
              <span className="capitalize">
                {competition.leaderboardType} Leaderboard
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="h-4 w-4" />
            </div>
          </div>
        </CardContent>

        <CardFooter className="bg-gray-50 flex items-center justify-between">
          <span className="text-sm text-gray-600">Click to view details</span>
          <Button
            // type="default"
            // size="small"
            onClick={handleOpen}
            className="flex items-center"
          >
            <Edit className="h-4 w-4 mr-2" />
            Edit
          </Button>

          <Modal
            title="Edit Tournament"
            open={isModalOpen}
            onCancel={handleClose}
            footer={null} // No footer to keep the modal clean
            width="90vw" // 90% of the viewport width
            style={{ top: 20 }} // Positioning for better visibility
            bodyStyle={{ height: "85vh", overflowY: "auto" }} // Make content scrollable
          >
            {/* Pass the tournament data to the edit form */}
            <CreateOrEditTournament
              competition={competition}
              onSuccess={() => {
                handleClose(); // Close the modal
                if (refreshList) {
                  refreshList(); // Refresh the tournament list
                }
              }}
            />
          </Modal>
        </CardFooter>
      </Card>
    </>
  );
};

export default TournamentCard;
