import React, { useState } from "react";
import { Button as AntButton, Button, Drawer, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { uniqueId } from "lodash";
import McqViewer from "../../../components/dash-components/Mcq/McqViewer";
import { useUploadMcqQuestionsMutation } from "../../../quiz/CompetitionApiSlice";
import { IMCQQuestion } from "../../../components/dash-components/Mcq/types";
import McqEditor from "../../../components/dash-components/Mcq/McqEditor";

interface NewQuestionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (questions: IMCQQuestion[]) => void;
  uploadAudio: (formData: FormData) => Promise<string>;
  uploadImage: (formData: FormData) => Promise<string>;
  topicId?: string | null;
  subtopicId?: string | null;
  allConceptTags?: any[];
}

export const NewQuestionDrawer: React.FC<NewQuestionDrawerProps> = ({
  isOpen,
  onClose,
  onSave,
  uploadAudio,
  uploadImage,
  topicId,
  subtopicId,
  allConceptTags,
}) => {
  const [questions, setQuestions] = useState<IMCQQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<IMCQQuestion>();
  const [uploadQuestions, isSuccess] = useUploadMcqQuestionsMutation();

  const handleAddQuestion = () => {
    const newQuestion: IMCQQuestion = {
      id: uniqueId(),
      topic: topicId || undefined,
      subtopic: subtopicId || undefined,
      allConceptTags: allConceptTags || [],
      en_question: [],
      en_options: [],
      hi_question: undefined,
      hi_options: undefined,
      conceptTags: [],
      explaination: undefined,
      meta: [],
      type: "mcq",
      formatType: "FOUR_OPTIONS",
      status: "pending",
    };
    setCurrentQuestion(newQuestion);
  };

  const handleSaveQuestion = async (questionData: IMCQQuestion) => {
    setQuestions((prev) => [...prev, questionData]);
    setCurrentQuestion(undefined);
  };

  const handleFinish = async () => {
    try {
      const removedIdQuestion = questions.map(
        ({ id, allConceptTags, ...rest }) => rest
      );
      const uploadedQuestions = await uploadQuestions(removedIdQuestion);
      onSave(uploadedQuestions?.data);
      setQuestions([]);
      onClose();
    } catch (error) {
      message.error("Failed to save questions");
    }
  };

  return (
    <Drawer
      title="Add New Questions"
      open={isOpen}
      width="90%"
      onClose={onClose}
      extra={
        <div className="space-x-4">
          <AntButton onClick={handleAddQuestion}>
            <PlusCircleOutlined /> Add Another Question
          </AntButton>
          <Button
            type="primary"
            onClick={handleFinish}
            disabled={questions.length === 0}
          >
            Save All Questions
          </Button>
        </div>
      }
    >
      {currentQuestion ? (
        <McqEditor
          data={currentQuestion}
          onSave={handleSaveQuestion}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
        />
      ) : (
        <div className="space-y-4">
          {questions.map((q, index) => (
            <McqViewer
              key={q.id}
              question={q}
              onEdit={() => setCurrentQuestion(q)}
            />
          ))}
          {questions.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500 mb-4">No questions added yet</p>
              <AntButton onClick={handleAddQuestion}>
                <PlusCircleOutlined /> Add First Question
              </AntButton>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};
