import React, { createContext, useContext, useState } from 'react';
import { ChapterFilters } from '../types';

interface ChapterContextType {
  allConceptTags: any[];
  setAllConceptTags: (tags: any[]) => void;
  selectedClass: string | undefined;
  setSelectedClass: (classId: string | undefined) => void;
  selectedFilter: ChapterFilters;
  setSelectedFilter: (filter: ChapterFilters) => void;
}

export const ChapterContext = createContext<ChapterContextType | undefined>(undefined);

export const ChapterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [allConceptTags, setAllConceptTags] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>();
  const [selectedFilter, setSelectedFilter] = useState<ChapterFilters>({
    topicId: null,
    subtopicId: null,
  });

  return (
    <ChapterContext.Provider
      value={{
        allConceptTags,
        setAllConceptTags,
        selectedClass,
        setSelectedClass,
        selectedFilter,
        setSelectedFilter,
      }}
    >
      {children}
    </ChapterContext.Provider>
  );
};