import React from 'react';
import { BarChart3, Calendar } from 'lucide-react';

const MetricsEmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center p-12 min-h-[400px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
      <div className="flex gap-4 mb-6">
        <div className="rounded-full bg-gray-100 p-4">
          <BarChart3 className="w-12 h-12 text-gray-400" />
        </div>
        <div className="rounded-full bg-gray-100 p-4">
          <Calendar className="w-12 h-12 text-gray-400" />
        </div>
      </div>
      
      <h3 className="text-xl font-semibold text-gray-700 mb-2">
        Select Competition and Date Range
      </h3>
      
      <p className="text-gray-500 text-center max-w-md">
        Please select a competition and specify a date range above to view the metrics data.
      </p>
    </div>
  );
};

export default MetricsEmptyState;