import React from 'react';
import { FileDown } from 'lucide-react';
import { Button } from 'antd';

const CaseStudyTemplateDownload = ({
  filename = 'case-study-template.json',
  buttonText = 'Download Case Study Template'
}) => {
  const sampleCaseStudy = {
    "externalId": "c1isdjfisjfofsiofjsijuijioijooierjgoiejoijotyjoijoijojijoi",
    "grade": "10",
    "subject": "Physics",
    "chapter": "Force and Motion",
    "Level": 2,
    "conceptTags": [],
    "type": "case-study",
    "curriculumBoard": "ICSE",
    "statement1": "<p>Sarah was excited for her trip to the park. She packed her favorite red ball and a small kite in her backpack. The sun was shining brightly as she walked with her dad.</p>",
    "statement2": "<p>At the park, Sarah saw many children playing. Some were on the swings, while others were sliding down the big blue slide. Sarah decided to play with her ball first.</p>",
    "statement3": "<p>As Sarah threw her ball high in the air, a strong gust of wind blew it towards the pond. The ball landed with a splash! Sarah felt sad, but then she remembered her kite.</p>",
    "questions": [
      {
        "bloomLevel": 1,
        "question": "What did Sarah pack in her backpack for the park?",
        "options": [
          "A red ball and a kite",
          "A blue ball and a book",
          "A red kite and a sandwich",
          "A small ball and a big kite"
        ],
        "answer": "A red ball and a kite",
        "explanation": "The story mentions that Sarah packed her favorite red ball and a small kite in her backpack.",
        "hint": "Think about what Sarah could play with at the park. One is round, the other flies!"
      },
      {
        "bloomLevel": 2,
        "question": "How did Sarah feel when her ball landed in the pond?",
        "options": ["Happy", "Excited", "Sad", "Angry"],
        "answer": "Sad",
        "explanation": "The story states that 'Sarah felt sad' when her ball landed in the pond.",
        "hint": "If you lost your toy in water, how would you feel?"
      }
    ]
  };

  const downloadTemplate = () => {
    const jsonString = JSON.stringify(sampleCaseStudy, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button 
      onClick={downloadTemplate}
      className="flex items-center gap-2 bg-green-50 hover:bg-green-100 border-green-200"
    >
      <FileDown className="h-4 w-4 text-green-600" />
      <span className="text-green-700">{buttonText}</span>
    </Button>
  );
};

export default CaseStudyTemplateDownload;