import { Modal, Form, Input, Button, Space, Select, InputNumber, Tooltip, Typography, message } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import {
  useGetChapterConceptQuery,
  useUpdateChapterConceptMutation,
} from "../EditTopicsApiSlice";
import { 
  ConceptTitle, 
  ConceptBenefitIcons,
  getTitleDescription,
  getRangeOptions,
  IConceptTag 
} from "./type";

const { Text } = Typography;

interface EditConceptModalProps {
  visible: boolean;
  onCancel: () => void;
  conceptId: string;
  onConceptUpdated: () => void;
  conceptsData: any;
}

const EditConceptModal: React.FC<EditConceptModalProps> = ({
  visible,
  onCancel,
  conceptId,
  onConceptUpdated,
  conceptsData,
}) => {
  const {
    data: concept,
    isLoading: isFetching,
    refetch: refetchConcept,
  } = useGetChapterConceptQuery(conceptId);
  const [updateChapterConcept, { isLoading: isUpdating }] = useUpdateChapterConceptMutation();
  
  const [form] = Form.useForm();
  const [benefits, setBenefits] = useState<any[]>([]);
  const [meta, setMeta] = useState<{ key: string; value: string }[]>([]);
  const [conceptTags, setConceptTags] = useState<IConceptTag[]>([]);

  useEffect(() => {
    if (concept) {
      form.setFieldsValue({ name: concept.name });
      setBenefits(concept.benefits || []);
      setMeta(
        concept.meta
          ? Object.entries(concept.meta).map(([key, value]) => ({
              key,
              value: value as string,
            }))
          : []
      );
      if (concept.conceptTags && concept.conceptTags.length > 0) {
        setConceptTags(concept.conceptTags);
      }
    }
  }, [concept, form]);

  // Get name from title
  const getTitleName = (title: ConceptTitle): string => {
    const names: Record<ConceptTitle, string> = {
      [ConceptTitle.CRITICALITY]: "criticality",
      [ConceptTitle.LEARNING_STYLE]: "learningStyleAlignment",
      [ConceptTitle.LEARNING_CURVE]: "learningCurve",
      [ConceptTitle.COMPLEXITY]: "complexity",
      [ConceptTitle.TIME_TO_MASTER]: "timeToMaster",
      [ConceptTitle.DEPENDENCIES]: "dependencies",
      [ConceptTitle.DEPENDENTS_COUNT]: "dependentsCount",
      [ConceptTitle.LIFE_RELEVANCE]: "lifeRelevance",
      [ConceptTitle.ACADEMIC_RELEVANCE]: "academicRelevance",
      [ConceptTitle.JEE_RELEVANCE]: "jeeRelevance",
      [ConceptTitle.NEET_RELEVANCE]: "neetRelevance",
      [ConceptTitle.PRACTICAL_USES]: "practicalUses",
      [ConceptTitle.RETENTION_IMPORTANCE]: "retentionImportance",
      [ConceptTitle.REVIEW_FREQUENCY]: "reviewFrequency"
    };
    return names[title];
  };

  // Add new concept tag
  const addConceptTag = () => {
    setConceptTags([...conceptTags, {
      name: 'criticality',
      title: ConceptTitle.CRITICALITY,
      description: getTitleDescription(ConceptTitle.CRITICALITY),
      range: null
    }]);
  };

  // Remove concept tag
  const removeConceptTag = (index: number) => {
    setConceptTags(conceptTags.filter((_, i) => i !== index));
  };

  // Handle conceptTag change
  const handleConceptTagChange = (index: number, field: keyof IConceptTag, value: any) => {
    const updatedTags = [...conceptTags];
    const updatedTag = { ...updatedTags[index] };
    
    if (field === 'title') {
      updatedTag.title = value as ConceptTitle;
      updatedTag.name = getTitleName(value as ConceptTitle);
      updatedTag.description = getTitleDescription(value as ConceptTitle);
      updatedTag.range = null;
    } else if (field === 'range') {
      // Handle different types of range values
      if (updatedTag.title === ConceptTitle.DEPENDENCIES) {
        updatedTag.range = value as string[];
      } else {
        updatedTag.range = value;
      }
    } else {
      updatedTag[field] = value;
    }
    
    updatedTags[index] = updatedTag;
    setConceptTags(updatedTags);
  };

  const renderRangeInput = (tag: IConceptTag, index: number) => {
    if (!tag.title) return null;
    
    const rangeOptions = getRangeOptions(tag.title);
    const description = getTitleDescription(tag.title);

    if (tag.title === ConceptTitle.DEPENDENCIES) {
      const handleDependencyChange = (value: string[]) => {
        handleConceptTagChange(index, 'range', value);
      };
    
      return (
        <Space>
          <Select<string[]>
            mode="multiple"
            value={tag.range as string[] || []}
            onChange={handleDependencyChange}
            style={{ width: 400 }}
            placeholder="Select dependent concepts"
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => {
              if (typeof option?.label === 'string') {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }
              return false;
            }}
          >
            {conceptsData
              .filter((concept:any) => concept.id !== conceptId)
              .map((concept:any) => (
                <Select.Option 
                  key={concept.id} 
                  value={concept.id}
                  label={concept.name} // Add label prop
                >
                  {concept.name}
                </Select.Option>
              ))
            }
          </Select>
        </Space>
      );
    }

    if (Array.isArray(rangeOptions)) {
      return (
        <Select
          value={tag.range}
          onChange={(value) => handleConceptTagChange(index, 'range', value)}
          style={{ width: 200 }}
        >
          {rangeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
    }

    if (typeof rangeOptions === 'object' && rangeOptions.min !== undefined) {
      return (
        <Space>
          <InputNumber
            min={rangeOptions.min}
            max={rangeOptions.max}
            value={tag.range}
            onChange={(value) => handleConceptTagChange(index, 'range', value)}
          />
          <Text type="secondary" className="text-sm">
            (Min: {rangeOptions.min}, Max: {rangeOptions.max})
          </Text>
        </Space>
      );
    }

    if (rangeOptions === 'array of strings') {
      return (
        <Select
          mode="tags"
          value={tag.range || []}
          onChange={(value) => handleConceptTagChange(index, 'range', value)}
          style={{ width: 200 }}
        />
      );
    }

    if (rangeOptions === 'number' || rangeOptions.includes('number')) {
      const unit = rangeOptions.includes('days') ? 'days' : 'hours';
      return (
        <Space>
          <InputNumber
            min={0}
            value={tag.range}
            onChange={(value) => handleConceptTagChange(index, 'range', value)}
          />
          <Text type="secondary" className="text-sm">({unit})</Text>
        </Space>
      );
    }

    return null;
  };

  // Rest of the component remains the same...
  // Benefits and Meta handlers remain unchanged...
  const addBenefit = () => {
    setBenefits([...benefits, { text: "", icon: null }]);
  };

  const removeBenefit = (index: number) => {
    setBenefits(benefits.filter((_, i) => i !== index));
  };

  const handleBenefitChange = (
    index: number,
    field: "text" | "icon",
    value: string | ConceptBenefitIcons
  ) => {
    setBenefits(benefits.map((benefit, i) => 
      i === index ? { ...benefit, [field]: value } : benefit
    ));
  };

  // Meta handlers
  const addMetaField = () => {
    setMeta([...meta, { key: "", value: "" }]);
  };

  const removeMetaField = (index: number) => {
    setMeta(meta.filter((_, i) => i !== index));
  };

  const handleMetaChange = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    setMeta(meta.map((item, i) => 
      i === index ? { ...item, [field]: value } : item
    ));
  };
  const handleOk = async () => {
    try {
      const updatedConceptData = {
        ...form.getFieldsValue(),
        benefits: benefits.filter((b) => b.text.trim() !== ""),
        meta: meta.reduce(
          (acc, { key, value }) =>
            key.trim() && value.trim() ? { ...acc, [key]: value } : acc,
          {}
        ),
        conceptTags: conceptTags.filter(tag => tag.range !== null),
      };
      
      await updateChapterConcept({
        conceptId,
        conceptData: updatedConceptData,
      }).unwrap();
      message.success("Concept updated successfully!");
      refetchConcept();
      onConceptUpdated();
      onCancel();
    } catch (error) {
      console.error("Error updating concept:", error);
      message.error("Error updating concept. Please try again.");
    }
  };

  return (
    <Modal
      width={900}
      title="Edit Concept"
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={isUpdating || isFetching}
    >
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label="Concept Name" name="name" rules={[{ required: true }]}>
            <Select>
              {Object.values(getTitleName(ConceptTitle.CRITICALITY)).map((name) => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Concept Tags Section */}
          <div className="space-y-4">
            {conceptTags.map((tag, index) => (
              <Space key={index} align="baseline" className="w-full" size="large">
                <Select
                  value={tag.title}
                  onChange={(value) => handleConceptTagChange(index, 'title', value)}
                  style={{ width: 200 }}
                >
                  {Object.values(ConceptTitle).map((title) => (
                    <Select.Option key={title} value={title}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>

                {renderRangeInput(tag, index)}

                <Tooltip title={tag.description}>
                  <InfoCircleOutlined style={{ color: '#1890ff' }} />
                </Tooltip>

                <Button 
                  type="text" 
                  icon={<MinusOutlined />} 
                  onClick={() => removeConceptTag(index)}
                  danger
                />
              </Space>
            ))}

            <Button 
              type="dashed" 
              onClick={addConceptTag} 
              block
              icon={<PlusOutlined />}
            >
              Add Concept Tag
            </Button>
          </div>

            {/* Benefits and Meta sections remain unchanged... */}
            <h3>Benefits</h3>
          {benefits.map((benefit, index) => (
            <Space key={index} align="baseline" style={{ display: "flex", marginBottom: 8, gap: "8px" }}>
              <Form.Item label="Benefit Text">
                <Input
                  value={benefit.text}
                  onChange={(e) => handleBenefitChange(index, "text", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Benefit Icon">
                <Select
                  value={benefit.icon}
                  onChange={(value) => handleBenefitChange(index, "icon", value)}
                  style={{ width: 120 }}
                >
                  {Object.values(ConceptBenefitIcons).map((icon) => (
                    <Select.Option key={icon as any} value={icon}>
                      {icon as any}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button onClick={() => removeBenefit(index)}>Remove</Button>
            </Space>
          ))}
          <Button type="dashed" onClick={addBenefit} block style={{ marginBottom: 16 }}>
            Add Benefit
          </Button>

          {/* Meta Fields Section */}
          <h3>Meta Fields</h3>
          {meta.map((item, index) => (
            <Space key={index} className="w-full" align="baseline" style={{ display: "flex", marginBottom: 8, gap: "8px" }}>
              <Form.Item label="Key">
                <Input
                  value={item.key}
                  onChange={(e) => handleMetaChange(index, "key", e.target.value)}
                  placeholder="Meta Key"
                  className="w-[300px]"
                />
              </Form.Item>
              <Form.Item label="Value">
                <Input
                  value={item.value}
                  onChange={(e) => handleMetaChange(index, "value", e.target.value)}
                  placeholder="Meta Value"
                  className="w-[300px]"
                />
              </Form.Item>
              <Button onClick={() => removeMetaField(index)}>Remove</Button>
            </Space>
          ))}
          <Button type="dashed" onClick={addMetaField} block>
            Add Meta Field
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default EditConceptModal;