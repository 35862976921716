import React, { useState } from "react";
import {
  Modal,
  Select,
  TreeSelect,
  Button,
  Form,
  DatePicker,
  Input,
  Drawer,
  Card,
  message,
  Spin,
  Checkbox,
} from "antd";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import FilteredQuestions from "../../questions/FilteredQuestions";
import { IMCQQuestion } from "../../../components/dash-components/Mcq/types";
import { QuestionPreviewer } from "../../../quiz/CompetitionForms/CompetitionFormForClone";
import { useGetQuestionsCatalogueQuery } from "../../questions/QuestionsApiSlice";
import { useParams } from "react-router-dom";
import {
  useCreateIndividualActivityForStudentMutation,
  useCreateRemedialActivityMutation,
} from "../SchoolsApiSlice";
import RemedialQuestions from "./RemedialQuestions";

export enum ActivityType {
  Summative = "summative",
  Formative = "formative",
  FLN = "foundationalliteracynumeracy",
  ACTIVITY = "activity",
  REMEDIAL = "remedial",
}

interface Subtopic {
  id: string;
  name: string;
  concepts: {
    id: string;
    name: string;
  }[];
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

interface ClassData {
  id: string;
  name: string;
  topics: Topic[];
}

interface Props {
  visible?: boolean;
  onClose?: () => void;
  classData?: ClassData[];
  onSubmit?: (payload: {
    title: string;
    description: string;
    type: ActivityType;
    startDate: Dayjs;
    dueDate: Dayjs;
    categoryIds: string[];
    topics: string[];
    subtopics: string[];
    conceptIds: string[];
    bloomLevels: number[];
    questionIds?: string[];
  }) => void;
  studentIds: string[];
  showStudentsButton?: boolean;
}

const QuizModal: React.FC<Props> = ({
  visible,
  onClose,
  studentIds,
  showStudentsButton,
  // onSubmit,
}) => {
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [selectedSubtopicIds, setSelectedSubtopicIds] = useState<string[]>([]);
  const [selectedConceptIds, setSelectedConceptIds] = useState<string[]>([]);
  const [selectedBloomLevels, setSelectedBloomLevels] = useState<number[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<ActivityType>(ActivityType.REMEDIAL);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<IMCQQuestion[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showQuestionsDrawer, setShowQuestionsDrawer] = useState(false);
  const [weakConceptConditions, setWeakConceptConditions] = useState(false);
  const [remedialData, setRemedialData] = useState<any>([]); // Add this state

  // const { studentId } = useParams<{ studentId: string }>();
  const {
    data: classData,
    isLoading: isClassDataLoading,
    isFetching: isClassFetching,
  } = useGetQuestionsCatalogueQuery({});
  const [createActivity, { isLoading }] =
    useCreateIndividualActivityForStudentMutation();
  const [
    createRemedialActivity,
    { isLoading: remedialLoading, isSuccess, isError, error },
  ] = useCreateRemedialActivityMutation();

  // Helper function to generate tree data for topics
  const getTopicTreeData = () => {
    return selectedCategoryIds?.map((categoryId) => {
      const category = classData?.find((cat) => cat.id === categoryId);

      return {
        title: category?.name || "",
        value: categoryId,
        key: categoryId,
        selectable: false,
        children:
          category?.topics?.map((topic) => ({
            title: topic.name,
            value: topic.id,
            key: topic.id,
            selectable: true,
          })) || [],
      };
    });
  };

  // Helper function to generate tree data for subtopics
  const getSubtopicTreeData = () => {
    return selectedCategoryIds?.map((categoryId) => {
      const category = classData?.find((cat) => cat.id === categoryId);

      const filteredTopics = category?.topics?.filter((topic) =>
        selectedTopicIds.includes(topic.id)
      );

      return {
        title: category?.name || "",
        value: categoryId,
        key: categoryId,
        selectable: false,
        children: filteredTopics?.map((topic) => ({
          title: topic.name,
          value: topic.id,
          key: topic.id,
          selectable: false,
          children: topic.subtopics?.map((subtopic) => ({
            title: subtopic.name,
            value: subtopic.id,
            key: subtopic.id,
            selectable: true,
          })),
        })),
      };
    });
  };

  const handleSubmit = async () => {
    if (
      !title ||
      !type ||
      !startDate ||
      !dueDate ||
      selectedCategoryIds.length === 0 ||
      selectedTopicIds.length === 0 ||
      selectedSubtopicIds.length === 0
    ) {
      message.error(
        "Please fill in all required fields! Title, Type, Start Date, Due Date, Class, Subjects, and Chapters are required."
      );
      return;
    }
    setIsSubmitting(true);
    setSubmitDisabled(true);
    const questionIds = selectedQuestions.map((question: any) => question.id);
    const payload = {
      title,
      description,
      type,
      startDate,
      dueDate,
      bloomLevels: selectedBloomLevels,
      categoryIds: selectedCategoryIds,
      topics: selectedTopicIds,
      subtopics: selectedSubtopicIds,
      conceptIds: selectedConceptIds,
      ...(questionIds.length > 0 && { questionIds }),
      // studentIds
    };

    try {
      if (type === ActivityType.REMEDIAL) {
        // Call remedial activity API
        const remedialPayload = {
          studentIds,
          title,
          description,
          scope: {
            topicIds: selectedTopicIds,
            subtopicIds: selectedSubtopicIds,
            conceptIds: selectedConceptIds,
          },
          options: {
            override: weakConceptConditions,
          },
        };
        const response = await createRemedialActivity(remedialPayload).unwrap();
        setRemedialData(response);
      } else {
        await createActivity({
          activityData: payload,
          // studentId,
          bloomLevels: selectedBloomLevels,
          studentIds,
        }).unwrap();
      }
      message.success("Activity created successfully!");
      // navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error("Error creating activity:", error);
      message.error("Failed to create activity. Please try again.");
    } finally {
      setIsSubmitting(false); // Hide loading spinner
      setSubmitDisabled(true);
    }
  };
  const handleDeleteQuestion = (id?: string) => {
    if (id) {
      const updatedQuestions = selectedQuestions.filter(
        (question) => question.id !== id
      );
      setSelectedQuestions(updatedQuestions);
      // setSelectedQuestionIds((prevIds) => prevIds.filter((qId) => qId !== id));
    }
  };
  return (
    <Card>
      {isClassFetching ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ): (
        <>
          {remedialData.length > 0 ? (
            <RemedialQuestions data={remedialData} />
          )  : (
        <div className="mb-6 flex flex-col gap-3">
          <Form.Item
            label="Title"
            
            // rules={[{ required: true, message: "Please input title!" }]}
          >
            <Input
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Description">
            <Input.TextArea
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label="Type"
            required
            rules={[{ required: true, message: "Please select type!" }]}
          >
            <Select
              value={type}
              onChange={(value) => setType(value)}
              options={Object.entries(ActivityType)?.map(([key, value]) => ({
                label: key,
                value: value,
              }))}
            />
          </Form.Item>

          <div className="flex gap-4">
            <Form.Item
              label="Start Date"
           
              className="flex-1"
              // rules={[{ required: true, message: "Please select start date!" }]}
            >
              <DatePicker
                className="w-full"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                disabledDate={(current) => {
                  return current && current < dayjs().startOf("day");
                }}
              />
            </Form.Item>

            <Form.Item
              label="Due Date"
             
              className="flex-1"
              // rules={[{ required: true, message: "Please select due date!" }]}
            >
              <DatePicker
                className="w-full"
                value={dueDate}
                onChange={(date) => setDueDate(date)}
                disabledDate={(current) => {
                  return (
                    current && current < (startDate || dayjs().startOf("day"))
                  );
                }}
              />
            </Form.Item>
          </div>
          <Form.Item label="Class" required>
            <Select
              mode="multiple"
              placeholder="Select Class"
              value={selectedCategoryIds}
              onChange={(value) => setSelectedCategoryIds(value)}
              options={classData?.map((classItem) => ({
                label: classItem.name,
                value: classItem.id,
              }))}
            />
          </Form.Item>

          <Form.Item label="Subjects" required>
            <TreeSelect
              disabled={selectedCategoryIds?.length === 0}
              treeData={getTopicTreeData()}
              value={selectedTopicIds}
              onChange={(value) =>
                setSelectedTopicIds(Array.isArray(value) ? value : [value])
              }
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="Select Subjects"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item label="Chapters" required>
            <TreeSelect
              disabled={selectedTopicIds?.length === 0}
              treeData={getSubtopicTreeData()}
              value={selectedSubtopicIds}
              onChange={(value) =>
                setSelectedSubtopicIds(Array.isArray(value) ? value : [value])
              }
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="Select Chapters"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item label="Concepts" name="conceptIds">
            <TreeSelect
              disabled={selectedSubtopicIds.length === 0}
              treeData={selectedSubtopicIds.map((subtopicId) => {
                // Find the category and its corresponding topic/subtopic
                const subtopic = classData
                  ?.flatMap((category) =>
                    category.topics.flatMap((topic) => topic.subtopics)
                  )
                  .find((sub) => sub.id === subtopicId);

                return {
                  title: subtopic?.name,
                  value: subtopicId,
                  key: subtopicId,
                  selectable: false,
                  children: subtopic?.concepts?.map((concept) => ({
                    title: concept.name,
                    value: concept.id,
                    key: concept.id,
                    selectable: true,
                  })),
                };
              })}
              value={selectedConceptIds}
              onChange={(value) => setSelectedConceptIds(value)}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="Select Concepts"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            Note: Once you select a class, subject, and chapters, 10 to 13
            questions will be automatically chosen for you if no specific
            questions are manually selected.
          </div>

          <Form.Item label="Bloom Levels">
            <Select
              mode="multiple"
              placeholder="Select Bloom Levels"
              value={selectedBloomLevels}
              onChange={(value) => setSelectedBloomLevels(value)}
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={weakConceptConditions}
              onChange={(e) => setWeakConceptConditions(e.target.checked)}
            >
              Weak concept conditions
            </Checkbox>
          </Form.Item>

          <Button
            className="w-[200px]"
            onClick={() => setShowQuestionsDrawer(true)}
          >
            Select Questions
          </Button>
          <div className="  mt-6 w-full ">
            <QuestionPreviewer
              selectedQuestions={selectedQuestions}
              onDelete={handleDeleteQuestion}
            />
            <div className="flex justify-end mt-4 gap-4">
              {showStudentsButton && (
                <Button onClick={onClose} type="default">
                  Go to Students Table
                </Button>
              )}

              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isSubmitting} // Show loading spinner while submitting
                disabled={submitDisabled} // Disable button after submit
              >
                Submit
              </Button>
            </div>
          </div>
                </div>
            )}
            </>
      )}

      <Drawer
        title="Select Questions"
        placement="right" // Adjust placement as needed
        width={1300} // Adjust width as needed
        onClose={() => setShowQuestionsDrawer(false)}
        open={showQuestionsDrawer}
        height={500}
      >
        <FilteredQuestions
          isSelect={true}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      </Drawer>
    </Card>
  );
};

export default QuizModal;

// const handleSubmit = () => {
//   if (!startDate || !dueDate) return;
//   const questionIds = selectedQuestions.map((question: any) => question.id);
//   // onSubmit({
//   //   title,
//   //   description,
//   //   type,
//   //   startDate,
//   //   dueDate,
//   //   categoryIds: selectedCategoryIds,
//   //   topics: selectedTopicIds,
//   //   subtopics: selectedSubtopicIds,
//   //   conceptIds: selectedConceptIds,
//   //   bloomLevels: selectedBloomLevels,
//   //   ...(questionIds.length > 0 && { questionIds }),
//   // });

//   // Reset all states
//   setTitle("");
//   setDescription("");
//   setType(ActivityType.REMEDIAL);
//   setStartDate(null);
//   setDueDate(null);
//   setSelectedCategoryIds([]);
//   setSelectedTopicIds([]);
//   setSelectedSubtopicIds([]);
//   setSelectedConceptIds([]);
//   setSelectedBloomLevels([]);
//   setSelectedQuestions([]);
//   // onClose();
// };

{
  /* <Form.Item label="Concepts" name="conceptIds"> */
}
{
  /* <TreeSelect
          disabled={selectedSubtopicIds.length === 0}
          treeData={selectedSubtopicIds.map((subtopicId) => {
            // Find the category and its corresponding topic/subtopic
            const subtopic = classData
              ?.flatMap((category) =>
                category.topics.flatMap((topic) => topic.subtopics)
              )
              .find((sub) => sub.id === subtopicId);

            return {
              title: subtopic?.name,
              value: subtopicId,
              key: subtopicId,
              selectable: false,
              children: subtopic?.concepts?.map((concept) => ({
                title: concept.name,
                value: concept.id,
                key: concept.id,
                selectable: true,
              })),
            };
          })}
          value={selectedConceptIds}
          onChange={(value) => setSelectedConceptIds(value)}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          placeholder="Select Concepts"
          style={{ width: "100%" }}
        /> */
}
{
  /* </Form.Item> */
}
