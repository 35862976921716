import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Typography,
  message,
  Radio,
  Checkbox,
  TreeSelect,
  Upload,
  InputNumber,
} from "antd";
import {
  UploadOutlined,
  EditOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  UnlockOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Competition } from "./types";
import dayjs from "dayjs";
import {
  useCreateCompetitionMutation,
  useGetClassSectionsMutation,
} from "./TournamentsApiSlice";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { useGetSchoolsQuery } from "../../quiz/CompetitionApiSlice";
import { School } from "../schools/types";
import { useUploadImageMutation } from "../../app/api/apiSlice";

// import Cron from "react-cron-generator";
// import "react-cron-generator/dist/cron-builder.css";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export enum CompetitionRewardType {
  Cash = "cash",
  Voucher = "voucher",
}

const CreateTournament = () => {
  const [cronExpression, setCronExpression] = useState("* * * * * *");
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [selectedSubtopicIds, setSelectedSubtopicIds] = useState<string[]>([]);
  const [availableTopics, setAvailableTopics] = useState<any[]>([]);
  const [availableSubtopics, setAvailableSubtopics] = useState<any[]>([]);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState([]);
  const [availableClassSections, setAvailableClassSections] = useState<any[]>(
    []
  );
  const [rewardType, setRewardType] = useState<CompetitionRewardType | null>(
    null
  );
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [rewardMeta, setRewardMeta] = useState([
    { title: "", description: "" },
  ]);
  const [coverImageUrls, setCoverImageUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedClassSectionIds, setSelectedClassSectionIds] = useState([]);
  const [availableUsers, setAvailableUsers] = useState<any[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isParticipantOpen, setIsParticipantOpen] = useState(false);
  const [createCompetition, { isLoading }] = useCreateCompetitionMutation();
  const [form] = Form.useForm();

  const { data: classData, isLoading: isClassDataLoading } =
    useGetQuestionsCatalogueQuery({});
  const [uploadAsset] = useUploadImageMutation();
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    isError: isSchoolsError,
  } = useGetSchoolsQuery({});
  const [
    getClassSections,
    { isLoading: isClassSectionsLoading, isError: isClassSectionsError },
  ] = useGetClassSectionsMutation();

  useEffect(() => {
    if (selectedCategoryIds.length > 0) {
      const topics = selectedCategoryIds.flatMap((categoryId) => {
        const category = classData?.find((cat) => cat.id === categoryId);
        return category ? category.topics : [];
      });
      setAvailableTopics(topics);
      setSelectedTopicIds([]);
      setAvailableSubtopics([]);
      setSelectedSubtopicIds([]);
    }
  }, [selectedCategoryIds, classData]);

  useEffect(() => {
    if (selectedTopicIds.length > 0) {
      const subtopics = selectedTopicIds.flatMap((topicId) => {
        const topic: any = availableTopics.find(
          (top: any) => top.id === topicId
        );
        return topic ? topic.subtopics : [];
      });
      setAvailableSubtopics(subtopics);
      setSelectedSubtopicIds([]);
    }
  }, [selectedTopicIds, availableTopics]);

  const handleCategoryChange = (value: string[]) => {
    setSelectedCategoryIds(value);
  };

  const handleTopicChange = (value: string[]) => {
    setSelectedTopicIds(value);
  };

  const handleSubtopicChange = (value: string[]) => {
    setSelectedSubtopicIds(value);
  };
  useEffect(() => {
    const fetchClassSections = async () => {
      if (selectedCategoryIds.length) {
        try {
          const data = await getClassSections({
            schools: selectedSchoolIds,
            categoryIds: selectedCategoryIds,
          }).unwrap();
          // setClassSectionsData(data);
          console.log("data", data);
          setAvailableClassSections(data);
        } catch (error) {
          console.error("Error fetching class sections:", error);
          message.error("Failed to load class sections.");
        }
      } else {
        setAvailableClassSections([]);
        // setClassSectionsData([]); // Reset if dependencies change
      }
    };

    fetchClassSections();
  }, [selectedSchoolIds, selectedCategoryIds, getClassSections]);

  console.log("classSections", availableClassSections);
  useEffect(() => {
    if (selectedCategoryIds.length > 0) {
      const topics = selectedCategoryIds.flatMap((categoryId) => {
        const category = classData?.find(
          (classItem) => classItem.id === categoryId
        );
        return category ? category.topics : [];
      });
      setAvailableTopics(topics as any);
      setSelectedTopicIds([]); // Reset selected topics when categories change
      setAvailableSubtopics([]); // Reset subtopics when categories change
      setSelectedSubtopicIds([]);
    }
  }, [selectedCategoryIds, classData]);

  useEffect(() => {
    if (selectedTopicIds.length > 0) {
      const subtopics = selectedTopicIds.flatMap((topicId) => {
        const topic: any = availableTopics.find(
          (topic: any) => topic.id === topicId
        );
        return topic ? topic.subtopics : [];
      });
      setAvailableSubtopics(subtopics as any);
      setSelectedSubtopicIds([]);
    }
  }, [selectedTopicIds, availableTopics]);

  const handleAddReward = () => {
    setRewardMeta([...rewardMeta, { title: "", description: "" }]);
  };

  // Handler to update a specific reward
  const handleRewardChange = (index: number, field: string, value: any) => {
    const updatedRewards = [...rewardMeta];
    updatedRewards[index] = { ...updatedRewards[index], [field]: value };
    setRewardMeta(updatedRewards);
  };

  // Handler to remove a reward
  const handleRemoveReward = (index: number) => {
    setRewardMeta(rewardMeta.filter((_, i) => i !== index));
  };
  const onFinish = async (values: any) => {
    const formattedStartDate = values.startDate;
    const formattedEndDate = values.endDate;

    const {
      topicIds,
      classSectionIds,
      categoryIds,
      subtopicIds,
      schoolIds,
      open,
      coverImages,
      dateRange,
      ...restOfValues
    } = values;
    const competitionData = {
      ...restOfValues,
      coverImages: coverImageUrls,
      startDate,
      endDate,
      scheduleCronExpression: cronExpression,
      rewardMeta: rewardMeta,
      participants: {
        schoolIds: selectedSchoolIds,
        gradeLevels: selectedGradeLevels,
        classSectionIds: selectedClassSectionIds,
        userIds: selectedUserIds,
        isOpen: isParticipantOpen,
      },
      competitionScope: {
        categoryIds: selectedCategoryIds,
        topicIds: selectedTopicIds,
        subtopicIds: selectedSubtopicIds,
        open: values.open,
      },
    };

    console.log("Submitting:", competitionData); // Check the submitted data

    try {
      await createCompetition(competitionData).unwrap();
      message.success("Competition created successfully!");
        form.resetFields();
        // setSelectedClass(null);
        // setSelectedTopics([]);
        setAvailableTopics([]);
        setCronExpression("");
    } catch (error) {
      console.error("Error creating competition:", error);
      message.error("Failed to create competition.");
    }
  };

  const handleImageUpload = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const imageUrl = await uploadAsset({
        formData: formData,
        prefix: "mcq-image",
      }).unwrap();

      setCoverImageUrls((prevUrls) => [...prevUrls, imageUrl.url]);
      setFileList((prevList) => [
        ...prevList,
        {
          uid: file.uid,
          name: file.name,
          url: imageUrl.url,
          status: "done",
        },
      ]);
      message.success("Image uploaded successfully!");

      onSuccess("ok");
    } catch (error) {
      message.error("Image upload failed.");
      console.error("Error uploading image:", error);

      // Call onError to handle the error in the Upload component
      onError(error);
    }
  };

  if (isClassDataLoading) {
    return <div>Loading class data...</div>;
  }

  if (!classData) {
    return <div>Failed to load class data</div>; // Or handle the error appropriately
  }

  return (
    <div className="p-4">
      <Title level={2}>Create Tournament</Title>
      <Form
        form={form}
        name="create-tournament"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off" // Disable auto-complete
      >
        {/* Title */}
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input placeholder="Enter tournament title" />
        </Form.Item>

        {/* Subtitle */}
        <Form.Item
          label="Subtitle"
          name="subtitle"
          rules={[{ required: true, message: "Please input the subtitle!" }]}
        >
          <Input placeholder="Enter tournament subtitle" />
        </Form.Item>

        {/* Description */}
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Enter tournament description" />
        </Form.Item>

        <Form.Item label="Type" name="type">
          <Select placeholder="Select tournament type">
            <Select.Option value="competition">Competition</Select.Option>
            <Select.Option disabled value="challenge">
              Challenge
            </Select.Option>
          </Select>
        </Form.Item>

        
        <Form.Item label="Tournament Dates" name="dateRange">
          <RangePicker
            format="YYYY-MM-DD"
            onChange={(_, dateStrings) => {
              const [start, end] = dateStrings;
              setStartDate(start);
              setEndDate(end);
              form.setFieldsValue({ startDate: start, endDate: end });
            }}
            value={
              startDate && endDate
                ? [dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]
                : undefined
            }
          />
        </Form.Item>

        <Title level={4}>Competition Scope</Title>
        <div className="mb-6 ml-5 ">
          <Form.Item label="Categories" name="categoryIds">
            <Select
              mode="multiple"
              placeholder="Select Categories"
              onChange={(value) => setSelectedCategoryIds(value)}
              loading={isClassDataLoading}
            >
              {classData?.map((classItem) => (
                <Select.Option key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Topic Tree-Select */}
          <Form.Item label="Topics" name="topicIds">
            <TreeSelect
              disabled={selectedCategoryIds.length === 0}
              treeData={selectedCategoryIds.map((categoryId) => {
                const category = classData?.find(
                  (cat) => cat.id === categoryId
                );
                return {
                  title: category?.name,
                  value: categoryId,
                  key: categoryId,
                  selectable: false,
                  children: category?.topics.map((topic: any) => ({
                    title: topic.name,
                    value: topic.id,
                    key: topic.id,
                    selectable: true,
                  })),
                };
              })}
              value={selectedTopicIds}
              onChange={handleTopicChange}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="Select Topics"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item label="Subtopics" name="subtopicIds">
            <TreeSelect
              disabled={selectedTopicIds.length === 0}
              treeData={selectedCategoryIds.map((categoryId) => {
                // Find the category data
                const category = classData?.find(
                  (cat) => cat.id === categoryId
                );

                // Filter topics to only show selected ones
                const filteredTopics = category?.topics?.filter((topic: any) =>
                  selectedTopicIds.includes(topic.id)
                );

                return {
                  title: category?.name,
                  value: categoryId,
                  key: categoryId,
                  selectable: false,
                  children: filteredTopics?.map((topic: any) => ({
                    title: topic.name,
                    value: topic.id,
                    key: topic.id,
                    selectable: false,
                    children: topic.subtopics?.map((subtopic: any) => ({
                      title: subtopic.name,
                      value: subtopic.id,
                      key: subtopic.id,
                      selectable: true,
                    })),
                  })),
                };
              })}
              value={selectedSubtopicIds}
              onChange={handleSubtopicChange}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="Select Subtopics"
              style={{ width: "100%" }}
            />
          </Form.Item>

          {/* Open/Closed Selection (using Radio.Group) */}
          <Form.Item label="Open/Close" name="open">
            <Radio.Group>
              <Radio value={true}>Open</Radio>
              <Radio value={false}>Close</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Title level={4}>Participants</Title>
        <div className="mb-6 ml-5">
          <Form.Item label="Schools" name="schoolIds">
            <Select
              mode="multiple"
              placeholder="Select Schools"
              loading={isSchoolsLoading}
              onChange={(value) => setSelectedSchoolIds(value)}
              disabled={isSchoolsError}
            >
              {schoolsData?.map((school: School) => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name} - {school.city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Grade Levels Multi-Select */}
          <Form.Item label="Grade Levels" name="gradeLevels">
            <Select
              mode="multiple"
              placeholder="Select Grade Levels"
              onChange={(value) => setSelectedGradeLevels(value)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
                <Select.Option key={grade} value={grade}>
                  {grade}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Class Section IDs Multi-Select */}
          <Form.Item
            label="Class Sections (need to select categories and schools)"
            name="classSectionIds"
          >
            <Select
              disabled={
                selectedCategoryIds.length === 0 ||
                selectedSchoolIds.length === 0
              }
              mode="multiple"
              placeholder="Select Class Sections"
              onChange={(value) => setSelectedClassSectionIds(value)}
            >
              {/* Assuming class section options are available in some state or fetched data */}
              {availableClassSections?.map((section) => (
                <Select.Option key={section.id} value={section.id}>
                  {section.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* User IDs Multi-Select */}
          <Form.Item label="User IDs" name="userIds">
            <Select
              disabled
              mode="multiple"
              placeholder="Select Users"
              onChange={(value) => setSelectedUserIds(value)}
            >
              {/* Assuming user options are available in some state or fetched data */}
              {availableUsers?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Participants" name="participants">
            {/* Checkbox for isOpen */}
            <Checkbox
              checked={isParticipantOpen}
              onChange={(e) => setIsParticipantOpen(e.target.checked)}
            >
              Open for Participants
            </Checkbox>

            {/* ... other participant-related fields (if any) */}
          </Form.Item>
        </div>

        <Form.Item label="Participant Schools" name="participantSchools">
          <Select
            mode="multiple"
            placeholder="Select participant schools"
            loading={isSchoolsLoading}
            // onChange={(value) => setSelectedSchoolIds(value)}
            disabled={isSchoolsError}
          >
            {schoolsData?.map((school: School) => (
              <Select.Option key={school.id} value={school.id}>
                {school.name} - {school.city}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="coverImages" label="Cover Images">
          <Upload
            accept="image/*"
            listType="picture"
            customRequest={handleImageUpload}
            fileList={fileList} // Use fileList to display uploaded images
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setFileList(newFileList);
              setCoverImageUrls((prevUrls) =>
                prevUrls.filter((url) => url !== file.url)
              );
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        {/* Leaderboard Type (using Select) */}
        <Form.Item label="Leaderboard Type" name="leaderboardType">
          <Select placeholder="Select leaderboard type">
            <Select.Option value="score">Score</Select.Option>
            <Select.Option value="completion">Completion</Select.Option>
            <Select.Option value="gems">Gems</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Schedule Cron Expression">
          <Cron
            value={cronExpression}
            setValue={setCronExpression}
            clearButton={true}
            // clearButtonText="Clear"
          />
        </Form.Item>
        {/* Reward Type Field */}
        <Form.Item
          label="Reward Type"
          name="rewardType"
       
        >
          <Select
            placeholder="Select Reward Type"
            value={rewardType}
            onChange={(value) => setRewardType(value)}
            style={{ width: "200px" }}
          >
            <Select.Option value={CompetitionRewardType.Cash}>
              Cash
            </Select.Option>
            <Select.Option value={CompetitionRewardType.Voucher}>
              Voucher
            </Select.Option>
          </Select>
        </Form.Item>

        {/* Rewards Meta Field */}
        <Form.Item label="Rewards">
          {rewardMeta.map((reward, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              {/* Title Field */}
              <Input
                placeholder="Title"
                value={reward.title}
                onChange={(e) =>
                  handleRewardChange(index, "title", e.target.value)
                }
                style={{ width: "200px" }}
              />

              {/* Description Field */}
              <Input
                placeholder="Description"
                value={reward.description}
                onChange={(e) =>
                  handleRewardChange(index, "description", e.target.value)
                }
                style={{ width: "200px" }}
              />

              {/* Remove Button */}
              {rewardMeta.length > 1 && (
                <Button
                  type="dashed"
                  onClick={() => handleRemoveReward(index)}
                  icon={<DeleteOutlined />}
                />
              )}
            </div>
          ))}

          {/* Add Reward Button */}
          <Button
            type="dashed"
            onClick={handleAddReward}
            icon={<PlusCircleOutlined />}
          >
            Add Reward
          </Button>
        </Form.Item>

        {/* ... other form fields (type, scheduleCronExpression, competitionScope) */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateTournament;
