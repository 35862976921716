import styled from "styled-components";
import { useEffect, useRef, useState, useCallback } from "react";
import questionsApi, {
  useGetQuestionsCatalogueQuery,
  useGetQuestionsQuery,
  useLazyGetChapterConceptsQuery,
  useUpdateQuestionMutation,
  useUpdateQuestionStatusMutation,
} from "./QuestionsApiSlice";
import {
  Drawer,
  message,
  Typography,
  Radio,
  Checkbox,
  Select,
  Spin,
  Empty,
} from "antd";
import { Question, QuestionStatus } from "./types";
import { MathJaxContext } from "better-react-mathjax";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { LoadingOutlined } from "@ant-design/icons";
import { useThemeColors } from "../../app/theme/useThemeColors";
import TopicSelector from "../../components/dash-components/TopicSelector";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import MCQEditor from "../../components/dash-components/Mcq/McqEditor";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { EmptyStateContent } from "../chapters/components/EmptyStatContent";
import { CurriculumBoard } from "../EditTopics/EditTopicsApiSlice";

interface QuestionsProps {
  isSelect?: boolean;
  setSelectedQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
  selectedQuestions?: Question[];
  selectedQuestionIds?: string[];
  setSelectedQuestionIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function Questions({
  isSelect,
  setSelectedQuestions,
  selectedQuestionIds = [],
  setSelectedQuestionIds,
  selectedQuestions = [],
}: QuestionsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestionsState, setSelectedQuestionsState] = useState<
    Question[]
  >([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState("cbq");
  const [canLoadMore, setCanLoadMore] = useState(true);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [allConceptTags, setAllConceptTags] = useState<any[]>();
  const [curriculumBoard, setCurriculumBoard] = useState<CurriculumBoard | "">(
    CurriculumBoard.ICSE
  );
  const [conceptTagFilter, setConceptTagFilter] = useState("");
  const {
    data: classData,
    isSuccess: isClassDataSuccess,
    isFetching: isClassDataFetching,
  } = useGetQuestionsCatalogueQuery({
    curriculumBoard: curriculumBoard as CurriculumBoard,
  });
  const [saveStatus] = useUpdateQuestionStatusMutation();
  const [saveQuestion] = useUpdateQuestionMutation();
  const [uploadAsset] = useUploadImageMutation();
  const [
    triggerGetChapterQuery,
    { data: lazyData, isLoading: lazyLoading, error: lazyError },
  ] = useLazyGetChapterConceptsQuery();

  const [selectedFilter, setSelectedFilter] = useState<{
    topicId: string | null;
    subtopicId: string | null;
  }>({
    topicId: null,
    subtopicId: null,
  });

  const LIMIT = 50;
  const colors = useThemeColors();
  const isInitialState = false;
  // !selectedFilter.subtopicId;

  const {
    data: questionList,
    isFetching,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetQuestionsQuery({
    skip: (page - 1) * LIMIT,
    limit: LIMIT,
    topicId: selectedFilter.topicId || null,
    subtopicId: selectedFilter.subtopicId || null,
    questionType: "",
    curriculumBoard: curriculumBoard as CurriculumBoard,
    conceptTagsExist:
      conceptTagFilter === "withConceptTags"
        ? "true"
        : conceptTagFilter === "withoutConceptTags"
        ? "false"
        : undefined,
  });
  const handleChange = (value: CurriculumBoard | "") => {
    setCurriculumBoard(value);
  };

  useEffect(() => {
    // Refetch whenever necessary to avoid caching
    refetch();
  }, [page, selectedFilter]);

  useEffect(() => {
    if (isSuccess && questionList) {
      // Reset questions for the first page
      if (page === 1) {
        setQuestions(questionList);
      } else {
        // Append questions for subsequent pages
        setQuestions((prevQuestions) => {
          const newQuestions = questionList.filter(
            (newQuestion) => !prevQuestions.some((q) => q.id === newQuestion.id)
          );
          return [...prevQuestions, ...newQuestions];
        });
      }

      // Stop pagination if fewer than 50 questions are returned
      if (questionList?.length < LIMIT) {
        setCanLoadMore(false);
      } else {
        setCanLoadMore(true);
      }
    }
  }, [isSuccess, questionList, page]);

  useEffect(() => {
    if (isError) {
      console.error("Error fetching questions:", error);
      message.error("Failed to fetch questions. Please try again.");
    }
  }, [isError, error]);

  const handleScroll = useCallback(() => {
    if (!scrollDivRef.current || isFetching || !canLoadMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching, canLoadMore]);

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const handleStatusChange = async (questionId: string, status: QuestionStatus) => {
    try {
      await saveStatus({
        id: questionId,
        status,
      }).unwrap();
      
      // Update local state
      setQuestions(prevQuestions => 
        prevQuestions.map(q => 
          q.id === questionId ? { ...q, status } : q
        )
      );
      
      message.success(`Status updated to ${status}`);
    } catch (error) {
      console.error("Failed to update status:", error);
      message.error("Failed to update status");
    }
  };

  const updateQuestion = async (data: Question) => {
    try {
      // Create a copy and remove problemStatement before sending to API
      const dataToUpdate = { ...data };
      delete dataToUpdate.problemStatement;

      // Send updated data to API
      const result = await saveQuestion({
        id: data.id as string,
        data: dataToUpdate,
      }).unwrap();
      // refetch();
      // Restore the original problemStatement after the response
      const updatedQuestion = {
        ...result,
        problemStatement: data.problemStatement,
      };
      const chapterConcept = await triggerGetChapterQuery({
        chapterId: data.subtopic,
      });

      setEditQuestionIndex(-1);
      message.success("Question updated successfully");
      updateQuestionInState(
        data.id as string,
        updatedQuestion,
        chapterConcept?.data
      ); // Update with restored problemStatement
    } catch (error) {
      console.error("Failed to update question", error);
      message.error("Failed to update question");
    }
  };

  const updateQuestionInState = (
    id: string,
    data: Question,
    chapterConcept: any
  ) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === id
          ? {
              ...data,
              allConceptTags: chapterConcept,
              concept: q.concept,
              conceptTags: q.conceptTags,
            }
          : q
      )
    );

    // Update query cache as well
    dispatch(
      questionsApi.util.updateQueryData(
        "getQuestions",
        {
          skip: (page - 1) * LIMIT,
          limit: LIMIT,
          topicId: selectedFilter.topicId || null,
          subtopicId: selectedFilter.subtopicId || null,
          questionType: selectedQuestionType,
        },
        (draft) => {
          const index = draft.findIndex((q) => q.id === id);
          if (index !== -1) {
            draft[index] = data;
          }
        }
      )
    );
  };

  useEffect(() => {
    if (isSuccess) {
      // console.log("Questions fetched:", questionList, editQuestionIndex);
      // console.log(questions[editQuestionIndex]);
    }
  }, [isSuccess, questionList, editQuestionIndex]);

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || [];
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;
    setAllConceptTags(concepts);
    setPage(1);
    setSelectedFilter({
      topicId,
      subtopicId,
    });
  };

  useEffect(() => {
    let isSubscribed = true;

    const updateQuestionsWithConcepts = () => {
      if (
        questionList &&
        questionList?.length > 0 &&
        classData &&
        classData?.length > 0
      ) {
        const updatedQuestions = questionList.map((question) => {
          if (question.subtopic) {
            // Find the class and then the subtopic that matches
            const concepts = classData.flatMap((classItem) =>
              classItem.topics.flatMap(
                (topic) =>
                  topic.subtopics.find(
                    (subtopic) => subtopic.id === question.subtopic
                  )?.concepts || []
              )
            );

            return {
              ...question,
              allConceptTags: concepts,
              concept:
                question?.conceptTags?.map((item: any) => item?.name) || [],
            };
          }
          return question;
        });

        if (isSubscribed) {
          setQuestions(updatedQuestions);
        }
      }
    };

    updateQuestionsWithConcepts();

    return () => {
      isSubscribed = false;
    };
  }, [questionList, classData]);

  const handleQuestionSelect = (question: Question) => {
    if (setSelectedQuestions) {
      setSelectedQuestions((prevSelected) => {
        const isAlreadySelected = prevSelected.some(
          (q) => q.id === question.id
        );
        if (isAlreadySelected) {
          return prevSelected.filter((q) => q.id !== question.id);
        } else {
          return [...prevSelected, question];
        }
      });
    }
  };
  console.log(questions);
  const handleFilterChange = (value: string) => {
    setConceptTagFilter(value);
  };

  return (
    <>
      <Container>
        <div className=" mt-2.5 ml-4 flex flex-col text-start items-start justify-start ">
          <div className=" justify-start ">
            <Typography.Title level={2}>Questions</Typography.Title>
          </div>
          <div className="flex">
            <Select
              value={conceptTagFilter}
              onChange={handleFilterChange}
              style={{ width: 200 }}
              placeholder="Filter by Concept Tags"
              className="mr-2"
            >
              <Select.Option value="">All</Select.Option> {/* No filter */}
              <Select.Option value="withConceptTags">
                With Concept Tags
              </Select.Option>
              <Select.Option value="withoutConceptTags">
                Without Concept Tags
              </Select.Option>
            </Select>
            <Select
              style={{ width: 200 }}
              value={curriculumBoard}
              onChange={handleChange}
              placeholder="Select Curriculum Board"
              className="mr-2"
            >
              <Select.Option key="all" value="">
                All
              </Select.Option>
              {Object.values(CurriculumBoard).map((board) => (
                <Select.Option key={board} value={board}>
                  {board}
                </Select.Option>
              ))}
            </Select>
            {/* {classData && (
              <TopicSelector classes={classData} onChange={updateFilter} />
            )} */}
            {classData && isClassDataSuccess && !isClassDataFetching ? (
              <TopicSelector classes={classData} onChange={updateFilter} />
            ) : (
              <Spin
                size="small"
                className="ml-2"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: colors.primaryColor }}
                    spin
                  />
                }
              />
            )}
          </div>
        </div>
        {isInitialState ? (
          <EmptyStateContent />
        ) : (
          <MathJaxContext>
            <QuestionsContainer ref={scrollDivRef}>
              {questions.length === 0 && !isFetching && !isClassDataFetching ? (
                <div className="flex justify-center items-center h-full">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span className="text-gray-500">
                        No questions available. Please add some!
                      </span>
                    }
                  />
                </div>
              ) : (
                questions.map((question: any, idx: number) => (
                  <div key={question.id}>
                    {isSelect && (
                      <div className="w-full flex justify-end space-x-2 mr-6">
                        <span className="text-sm font-semibold">Select</span>
                        <Checkbox
                          className="w-7"
                          checked={selectedQuestions.some(
                            (q) => q.id === question.id
                          )}
                          onChange={() => handleQuestionSelect(question)} // Toggle selection
                        />
                      </div>
                    )}
                    <McqViewer
                      key={question.id}
                      question={question}
                      setQuestions={setQuestions}
                      classes={classData}
                      onEdit={() => setEditQuestionIndex(idx)}
                      onStatusChange={handleStatusChange}
                    />
                  </div>
                ))
              )}
              {isFetching && (
                <LoadingSpinnerContainer>
                  <LoadingOutlined
                    spin
                    style={{ fontSize: 48, color: colors.primaryColor }}
                  />
                </LoadingSpinnerContainer>
              )}
            </QuestionsContainer>
          </MathJaxContext>
        )}
      </Container>
      <Drawer
        open={editQuestionIndex > -1}
        onClose={() => setEditQuestionIndex(-1)}
        width={"90%"}
      >
        {editQuestionIndex > -1 && questions[editQuestionIndex] && (
          <MCQEditor
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
            onSave={updateQuestion}
            data={questions[editQuestionIndex] as IMCQQuestion}
          />
        )}
      </Drawer>
    </>
  );
}



















{/* {isInitialState ? (
          <EmptyStateContent />
        ) : (
          <MathJaxContext>
            <QuestionsContainer ref={scrollDivRef}>
              {questions.map((question: any, idx: number) => (
                <div key={question.id}>
                  {isSelect && (
                    <div className="  w-full flex  justify-end space-x-2 mr-6">
                      <span className="text-sm font-semibold">Select</span>
                      <Checkbox
                        className=" w-7"
                        checked={selectedQuestions.some(
                          (q) => q.id === question.id
                        )}
                        onChange={() => handleQuestionSelect(question)} // Toggle selection
                      />
                    </div>
                  )}
                  <McqViewer
                    key={question.id}
                    question={question}
                    setQuestions={setQuestions}
                    classes={classData}
                    onEdit={() => setEditQuestionIndex(idx)}
                  />
                </div>
              ))}
              {isFetching && (
                <LoadingSpinnerContainer>
                  <LoadingOutlined
                    spin
                    style={{ fontSize: 48, color: colors.primaryColor }}
                  />
                </LoadingSpinnerContainer>
              )}
            </QuestionsContainer>
          </MathJaxContext>
        )} */}
// useEffect(() => {
  //   const updateQuestionsWithConcepts = async () => {
  //     // Define async function

  //     const updatedQuestions = await Promise.all(
  //       // Use Promise.all
  //       questions.map(async (question) => {
  //         if (question.subtopic) {
  //           // Only fetch if subtopic exists
  //           const chapterConcept = await triggerGetChapterQuery({
  //             chapterId: question.subtopic,
  //           });

  //           return {
  //             ...question,
  //             allConceptTags: chapterConcept?.data, // Add or update allConceptTags
  //             concept:
  //               question?.conceptTags?.map((item: any) => item?.name) || [], // Provide default empty array
  //           };
  //         }
  //         return question;
  //       })
  //     );
  //     setQuestions(updatedQuestions);
  //   };

  //   updateQuestionsWithConcepts();
  // }, []);

  // useEffect(() => {
  //   let isSubscribed = true; // For cleanup
  //   const updateQuestionsWithConcepts = async () => {
  //     if (questionList && questionList.length > 0) {
  //       try {
  //         const updatedQuestions = await Promise.all(
  //           questionList.map(async (question) => {
  //             if (question.subtopic) {
  //               const chapterConcept = await triggerGetChapterQuery({
  //                 chapterId: question.subtopic,
  //               });

  //               return {
  //                 ...question,
  //                 allConceptTags: chapterConcept?.data,
  //                 concept:
  //                   question?.conceptTags?.map((item: any) => item?.name) || [],
  //               };
  //             }
  //             return question;
  //           })
  //         );
  //         if (isSubscribed) {
  //           setQuestions(updatedQuestions);
  //         }
  //       } catch (error) {
  //         console.error('Error updating questions with concepts:', error);
  //       }
  //     }
  //   };

  //   updateQuestionsWithConcepts();

  //   return () => {
  //     isSubscribed = false; // Cleanup
  //   };
  // }, [questionList, triggerGetChapterQuery]);

// const updateStatus = async (id: string, status: QuestionStatus) => {
//   try {
//     const result = await saveStatus({ id, status }).unwrap();
//     updateQuestionInState(id, result);
//   } catch (error) {
//     console.error("Failed to update question status", error);
//     message.error("Failed to update question status");
//   }
// };

// console.log(questions,"Questionssssss")

// useEffect(() => {
//   if (setSelectedQuestions) {
//     setSelectedQuestions(selectedQuestionsState);
//   }
// }, [selectedQuestions]);

// useEffect(() => {
//   if (allConceptTags) {
//     setQuestions((prevQuestions: IMCQQuestion[]) =>
//       prevQuestions.map((question) => {
//         // const chapterConcept = await triggerGetChapterQuery({ chapterId: question.subtopic });
//         return {
//           ...question, // Copy existing question data
//           allConceptTags: allConceptTags, // Add or update allConceptTags
//           concept: question?.conceptTags?.map((item: any) => {
//             return item?.name;
//           }),
//         };
//       })
//     );
//   }
// }, [allConceptTags]);

// import { useEffect, useState } from "react";
// import { Drawer, message, Typography, Checkbox, Empty } from "antd";
// import { MathJaxContext } from "better-react-mathjax";
// import { LoadingOutlined } from "@ant-design/icons";
// import { useThemeColors } from "../../app/theme/useThemeColors";
// import TopicSelector from "../../components/dash-components/TopicSelector";
// import McqViewer from "../../components/dash-components/Mcq/McqViewer";
// import MCQEditor from "../../components/dash-components/Mcq/McqEditor";
// import { Question } from "./types";
// import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
// import { useGetQuestionsCatalogueQuery } from "./QuestionsApiSlice";
// import styled from "styled-components";
// import { useQuestions } from "./hooks/useQuestions";
// import { EmptyStateContent } from "../chapters/components/EmptyStatContent";

// // Styled components (keeping existing ones)...

// interface QuestionsProps {
//   isSelect?: boolean;
//   setSelectedQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
//   selectedQuestions?: Question[];
//   selectedQuestionIds?: string[];
//   setSelectedQuestionIds?: React.Dispatch<React.SetStateAction<string[]>>;
// }

// export default function Questions({
//   isSelect,
//   setSelectedQuestions,
//   selectedQuestionIds = [],
//   setSelectedQuestionIds,
//   selectedQuestions = [],
// }: QuestionsProps) {
//   const colors = useThemeColors();
//   const [selectedFilter, setSelectedFilter] = useState<{
//     topicId: string | null;
//     subtopicId: string | null;
//   }>({
//     topicId: null,
//     subtopicId: null,
//   });

//   const isInitialState = !selectedFilter.subtopicId;

//   const {
//     questions,
//     setQuestions,
//     isFetching,
//     scrollDivRef,
//     editQuestionIndex,
//     setEditQuestionIndex,
//     allConceptTags,
//     setAllConceptTags,
//     updateQuestion,
//     updateStatus,
//     uploadImage,
//     uploadAudio,
//   } = useQuestions({
//     selectedFilter,
//     selectedQuestionType: "cbq",
//   });

//   const { data: classData, isSuccess: isClassDataSuccess } =
//     useGetQuestionsCatalogueQuery();

//   const handleQuestionSelect = (question: Question) => {
//     if (setSelectedQuestions) {
//       setSelectedQuestions((prevSelected) => {
//         const isAlreadySelected = prevSelected.some((q) => q.id === question.id);
//         if (isAlreadySelected) {
//           return prevSelected.filter((q) => q.id !== question.id);
//         } else {
//           return [...prevSelected, question];
//         }
//       });
//     }
//   };

//   const updateFilter = (selection: any) => {
//     const concepts = selection.concept || [];
//     const topicId = selection.topicId || undefined;
//     const subtopicId = selection.subtopicId || undefined;

//     setAllConceptTags(concepts);
//     setSelectedFilter({
//       topicId: topicId || null,
//       subtopicId: subtopicId || null,
//     });
//   };

//   return (
//     <>
//       <Container>
//         <HeaderContainer className="mt-2.5">
//           <Typography.Title level={2}>Questions</Typography.Title>
//           {classData && (
//             <TopicSelector classes={classData} onChange={updateFilter} />
//           )}
//         </HeaderContainer>

//         {isInitialState ? (
//           <EmptyStateContent />
//         ) : (
//           <MathJaxContext>
//             <QuestionsContainer ref={scrollDivRef}>
//               {questions.map((question: any, idx: number) => (
//                 <div key={question.id}>
//                   {isSelect && (
//                     <div className="w-full flex justify-end space-x-2 mr-6">
//                       <span className="text-sm font-semibold">Select</span>
//                       <Checkbox
//                         className="w-7"
//                         checked={selectedQuestions.some(
//                           (q) => q.id === question.id
//                         )}
//                         onChange={() => handleQuestionSelect(question)}
//                       />
//                     </div>
//                   )}
//                   <McqViewer
//                     key={question.id}
//                     question={question}
//                     setQuestions={setQuestions}
//                     classes={classData}
//                     onEdit={() => setEditQuestionIndex(idx)}
//                   />
//                 </div>
//               ))}
//               {isFetching && (
//                 <LoadingSpinnerContainer>
//                   <LoadingOutlined
//                     spin
//                     style={{ fontSize: 48, color: colors.primaryColor }}
//                   />
//                 </LoadingSpinnerContainer>
//               )}
//             </QuestionsContainer>
//           </MathJaxContext>
//         )}
//       </Container>

//       <Drawer
//         open={editQuestionIndex > -1}
//         onClose={() => setEditQuestionIndex(-1)}
//         width={"90%"}
//       >
//         {editQuestionIndex > -1 && questions[editQuestionIndex] && (
//           <MCQEditor
//             uploadAudio={uploadAudio}
//             uploadImage={uploadImage}
//             onSave={updateQuestion}
//             data={questions[editQuestionIndex] as IMCQQuestion}
//           />
//         )}
//       </Drawer>
//     </>
//   );
// }

const Container = styled.div`
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
`;

const QuestionsContainer = styled.div`
  padding: 0.5rem 1.5rem;
  overflow-y: auto;
  height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

// Styled components remain the same

// const updateQuestionInState = (id: string, data: Question) => {
//   console.log(data);
//   setQuestions((prevQuestions) =>
//     prevQuestions.map((q) => (q.id === id ? data : q))
//   );
//   const updatedData = { ...data };
// delete updatedData.problemStatement;
//   dispatch(
//     questionsApi.util.updateQueryData(
//       "getQuestions",
//       {
//         skip: (page - 1) * LIMIT,
//         limit: LIMIT,
//         topicId: selectedFilter.topicId || null,
//         subtopicId: selectedFilter.subtopicId || null,
//         questionType: "",
//       },
//       (draft) => {
//         const index = draft.findIndex((q) => q.id === id);
//         if (index !== -1) {
//           draft[index] = updatedData;
//         }
//       }
//     )
//   );
// };

// const updateQuestion = async (data: Question) => {
//   const updatedData: Question = { ...data };
// // delete updatedData.problemStatement;
//   try {
//     const result = await saveQuestion({
//       id: data.id as string,
//       data,
//     }).unwrap();
//     setEditQuestionIndex(-1);
//     message.success("Question updated successfully");
//     updateQuestionInState(data.id as string, result);
//   } catch (error) {
//     console.error("Failed to update question", error);
//     message.error("Failed to update question");
//   }
// };
