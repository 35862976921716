import { get } from "lodash";
import { apiSlice } from "../../app/api/apiSlice";

export enum CurriculumBoard {  
  ICSE = 'ICSE',
  CBSE = 'CBSE',
}

const editTopicApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ... (your existing endpoints remain the same)

    addTopicToCategory: builder.mutation({
      query: ({
        categoryId,
        topicData,
      }: {
        categoryId: string;
        topicData: any;
      }) => ({
        url: `/topics`,
        method: "POST",
        body: { ...topicData, parent: categoryId, type: "topic" }, // Include parent and type in the body
      }),
    }),

    addSubtopicToTopic: builder.mutation({
      query: ({
        categoryId,
        topicId,
        topicData,
      }: {
        categoryId: string;
        topicId: string;
        topicData: any;
      }) => ({
        url: `/topics`,
        method: "POST",
        body: { ...topicData, parent: topicId, type: "subtopic" }, // Include parent and type in the body
      }),
    }),

    updateTopic: builder.mutation({
      query: ({
        topicId,
        topicData,
      }: {
        topicId: string;
        topicData: {
          name: string;
          description?: string;
          coverImages?: string[];
        };
      }) => ({
        url: `/topics/${topicId}`,
        method: "PUT",
        body: topicData, // Send the entire topicData object in the body
      }),
    }),
    enableChaptersForTeachers: builder.mutation({
      query: (body: { topicId?: string; subtopicId?: string; questionCount?: number }) => ({
        url: `/enableChaptersForTeachers`,
        method: 'POST',
        body, // Pass the body object directly
      }),
    }),
    createChapterConcept: builder.mutation<any, { name: string; chapter: string }>({ // Use correct type for chapter
      query: (conceptData) => ({
        url: "/chapter-concepts",
        method: "POST",
        body: conceptData, 
      }),
    }),
    getChapterConcept: builder.query({
      query: (conceptId) => `/chapter-concepts/${conceptId}`,
    }),
    updateChapterConcept: builder.mutation({
      query: ({ conceptId, conceptData }) => ({
        url: `/chapter-concepts/${conceptId}`,
        method: "PUT",
        body: conceptData,
      }),
    }),
    getTopicConcepts: builder.query({
      query: ({chapterId}) => `/chapter-concepts?chapter=${chapterId}`,
    }),
  }),
});

export const {
  // ... (your existing hooks remain the same)
  useAddTopicToCategoryMutation,
  useAddSubtopicToTopicMutation,
  useUpdateTopicMutation,
  useEnableChaptersForTeachersMutation,
  useCreateChapterConceptMutation,
  useGetChapterConceptQuery,
  useUpdateChapterConceptMutation,
  useGetTopicConceptsQuery
} = editTopicApiSlice;