import { apiSlice } from "../../app/api/apiSlice";
import { ClassData } from "../../components/dash-components/TopicSelector";
import { CurriculumBoard } from "../EditTopics/EditTopicsApiSlice";
import { Question, QuestionStatus } from "./types";

const questionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getQuestionsCatalogue: builder.query<ClassData[], { curriculumBoard?: CurriculumBoard }>({
    //   query: ({ curriculumBoard }) => ({
    //     url: `/topics/catalogue?curriculumBoard=${curriculumBoard}`,
    //     method: "GET",
    //   }),
    // }),
    getQuestionsCatalogue: builder.query<
      ClassData[],
      { curriculumBoard?: CurriculumBoard }
    >({
      query: ({ curriculumBoard }) => {
        // Create base URL
        const baseUrl = "/topics/catalogue";

        // Only add curriculumBoard parameter if it exists and has a value
        const queryParams = new URLSearchParams();
        if (curriculumBoard) {
          queryParams.append("curriculumBoard", curriculumBoard);
        }

        // Construct final URL
        const url = queryParams.toString()
          ? `${baseUrl}?${queryParams.toString()}`
          : baseUrl;

        return {
          url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3600, 
    }),

    getChapterConcepts: builder.query<
      any[],
      {
        chapterId?: string;
        populate?: string;
        sortBy?: string;
        skip?: number;
        limit?: number;
      }
    >({
      query: ({ chapterId, populate, sortBy, skip, limit }) => {
        const params = new URLSearchParams();

        if (chapterId) params.append("chapter", chapterId);
        if (populate) params.append("populate", populate);
        if (sortBy) params.append("sortBy", sortBy);
        if (skip) params.append("skip", skip.toString());
        if (limit) params.append("limit", limit.toString());

        const queryString = params.toString();
        const url = `/chapter-concepts${queryString ? `?${queryString}` : ""}`;

        return { url, method: "GET" };
      },
    }),

    getQuestions: builder.query<
      Question[],
      {
        skip: number;
        topicId: string | null;
        subtopicId: string | null;
        limit: number;
        questionType: string;
        status?: string;
        curriculumBoard?: CurriculumBoard;
        conceptTagsExist?: string;
      }
    >({
      query: ({
        skip,
        topicId,
        subtopicId,
        limit,
        questionType,
        status,
        curriculumBoard,
        conceptTagsExist,
      }) => {
        const params: {
          skip: number;
          limit: number;
          topicId?: string;
          subtopicId?: string;
          questionType?: string;
          status?: string;
          curriculumBoard?: CurriculumBoard;
          conceptTagsExist?: string;
        } = {
          skip,
          limit,
          questionType,
          status,
          curriculumBoard,
          conceptTagsExist,
        };

        if (topicId) {
          params.topicId = topicId;
        }

        if (subtopicId) {
          params.subtopicId = subtopicId;
        }
        if (questionType) {
          params.questionType = questionType;
        }

        if (status) {
          params.status = status;
        }
        if (curriculumBoard) {
          params.curriculumBoard = curriculumBoard;
        }
        if (conceptTagsExist) {
          params.conceptTagsExist = conceptTagsExist;
        }
        return { url: "/questions", params };
      },
    }),
    updateQuestion: builder.mutation<Question, { id: string; data: Question }>({
      query: ({ id, data }) => ({
        url: `/questions/${id}`,
        method: "PUT",
        body: { ...data },
      }),
    }),

    updateQuestionStatus: builder.mutation<
      any,
      { id: string; status: QuestionStatus }
    >({
      query: ({ id, status }) => ({
        url: `/questions/${id}/status`,
        method: "PUT",
        body: { status },
      }),
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useUpdateQuestionStatusMutation,
  useUpdateQuestionMutation,
  useGetQuestionsCatalogueQuery,
  useLazyGetChapterConceptsQuery,
} = questionsApi;

export default questionsApi;
