import { Modal, Form, Input, Button, Select, Space, InputNumber, Tooltip, Typography, message } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useState } from "react";
import { CurriculumBoard, useCreateChapterConceptMutation } from "../EditTopicsApiSlice";
import { 
  ConceptBenefitIcons, 
  ConceptTitle, 
  IConceptBenefits, 
  IConceptTag,
  getTitleDescription,
  getRangeOptions 
} from "./type";

const { Text } = Typography;

interface CreateConceptModalProps {
  conceptsData: any;
  visible: boolean;
  onCancel: () => void;
  chapterId: string;
  onConceptCreated: () => void;
  refetch: () => void;
  curriculumBoard: CurriculumBoard | "";
}

const CreateConceptModal: React.FC<CreateConceptModalProps> = ({
  visible,
  onCancel,
  chapterId,
  onConceptCreated,
  refetch,
  curriculumBoard,
  conceptsData,
}) => {
  const [form] = Form.useForm();
  const [benefits, setBenefits] = useState<IConceptBenefits[]>([]);
  const [meta, setMeta] = useState<{ key: string; value: string }[]>([]);
  const [conceptTags, setConceptTags] = useState<IConceptTag[]>([]);
  const [createChapterConcept, { isLoading }] = useCreateChapterConceptMutation();

  // Get name from title
  const getTitleName = (title: ConceptTitle): string => {
    const names: Record<ConceptTitle, string> = {
      [ConceptTitle.CRITICALITY]: "criticality",
      [ConceptTitle.LEARNING_STYLE]: "learningStyleAlignment",
      [ConceptTitle.LEARNING_CURVE]: "learningCurve",
      [ConceptTitle.COMPLEXITY]: "complexity",
      [ConceptTitle.TIME_TO_MASTER]: "timeToMaster",
      [ConceptTitle.DEPENDENCIES]: "dependencies",
      [ConceptTitle.DEPENDENTS_COUNT]: "dependentsCount",
      [ConceptTitle.LIFE_RELEVANCE]: "lifeRelevance",
      [ConceptTitle.ACADEMIC_RELEVANCE]: "academicRelevance",
      [ConceptTitle.JEE_RELEVANCE]: "jeeRelevance",
      [ConceptTitle.NEET_RELEVANCE]: "neetRelevance",
      [ConceptTitle.PRACTICAL_USES]: "practicalUses",
      [ConceptTitle.RETENTION_IMPORTANCE]: "retentionImportance",
      [ConceptTitle.REVIEW_FREQUENCY]: "reviewFrequency"
    };
    return names[title];
  };

  console.log(conceptsData);
  // Add new concept tag
  const addConceptTag = () => {
    setConceptTags([...conceptTags, {
      name: getTitleName(ConceptTitle.CRITICALITY),
      title: ConceptTitle.CRITICALITY,
      description: getTitleDescription(ConceptTitle.CRITICALITY),
      range: null
    }]);
  };

  const removeConceptTag = (index: number) => {
    setConceptTags(conceptTags.filter((_, i) => i !== index));
  };

  const handleConceptTagChange = (index: number, field: keyof IConceptTag, value: any) => {
    const updatedTags = [...conceptTags];
    const updatedTag = { ...updatedTags[index] };
    
    if (field === 'title') {
      updatedTag.title = value;
      updatedTag.name = getTitleName(value);
      updatedTag.description = getTitleDescription(value);
      updatedTag.range = null;
    } else {
      updatedTag[field] = value;
    }
    
    updatedTags[index] = updatedTag;
    setConceptTags(updatedTags);
  };

  const renderRangeInput = (tag: IConceptTag, index: number) => {
    if (!tag.title) return null;
    
    const rangeOptions = getRangeOptions(tag.title);

    if (tag.title === ConceptTitle.DEPENDENCIES) {
      return (
        <Space>
          <Select
            mode="multiple"
            value={tag.range || []}
            onChange={(value) => handleConceptTagChange(index, 'range', value)}
            style={{ width: 400 }}
            placeholder="Select dependent concepts"
            optionFilterProp="children"
            showSearch
          >
            {conceptsData.map((concept: { id: string; name:string}) => (
              <Select.Option key={concept.id} value={concept.id}>
                {concept.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      );
    }

    if (Array.isArray(rangeOptions)) {
      return (
        <Select
          value={tag.range}
          onChange={(value) => handleConceptTagChange(index, 'range', value)}
          style={{ width: 200 }}
        >
          {rangeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
    }

    // if (typeof rangeOptions === 'object' && rangeOptions.min !== undefined) {
    //   return (
    //     <Space>
    //       <InputNumber
    //         min={rangeOptions.min}
    //         max={rangeOptions.max}
    //         value={tag.range}
    //         onChange={(value) => handleConceptTagChange(index, 'range', value)}
    //       />
    //       <Text type="secondary" className="text-sm">
    //         (Min: {rangeOptions.min}, Max: {rangeOptions.max})
    //       </Text>
    //     </Space>
    //   );
    // }
    if (typeof rangeOptions === 'object' && rangeOptions.min !== undefined) {
      const options = Array.from(
        { length: rangeOptions.max - rangeOptions.min + 1 },
        (_, i) => i + rangeOptions.min
      );
  
      return (
        <Space>
          <Select
            value={tag.range}
            onChange={(value) => handleConceptTagChange(index, 'range', value)}
            style={{ width: 120 }}
          >
            {options.map((num) => (
              <Select.Option key={num} value={num}>
                {num}
              </Select.Option>
            ))}
          </Select>
          <Text type="secondary" className="text-sm">
            (Min: {rangeOptions.min}, Max: {rangeOptions.max})
          </Text>
        </Space>
      );
    }
  

    if (rangeOptions === 'array of strings') {
      return (
        <Select
          mode="tags"
          value={tag.range || []}
          onChange={(value) => handleConceptTagChange(index, 'range', value)}
          style={{ width: 200 }}
          placeholder="Enter values and press enter"
        />
      );
    }

    if (rangeOptions === 'number' || rangeOptions.includes('number')) {
      const unit = rangeOptions.includes('days') ? 'days' : 'hours';
      return (
        <Space>
          <InputNumber
            min={0}
            value={tag.range}
            onChange={(value) => handleConceptTagChange(index, 'range', value)}
          />
          <Text type="secondary" className="text-sm">({unit})</Text>
        </Space>
      );
    }

    return null;
  };

  // Benefits handlers
  const addBenefit = () => {
    setBenefits([...benefits, { text: "", icon: ConceptBenefitIcons.TIMER }]);
  };

  const removeBenefit = (index: number) => {
    setBenefits(benefits.filter((_, i) => i !== index));
  };

  const handleBenefitChange = (index: number, field: "text" | "icon", value: string | ConceptBenefitIcons) => {
    setBenefits(benefits.map((benefit, i) => 
      i === index ? { ...benefit, [field]: value } : benefit
    ));
  };

  // Meta fields handlers
  const addMetaField = () => {
    setMeta([...meta, { key: "", value: "" }]);
  };

  const removeMetaField = (index: number) => {
    setMeta(meta.filter((_, i) => i !== index));
  };

  const handleMetaChange = (index: number, field: "key" | "value", value: string) => {
    setMeta(meta.map((item, i) => 
      i === index ? { ...item, [field]: value } : item
    ));
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const conceptData = {
        name: values.name,
        chapter: chapterId,
        benefits: benefits.filter((b) => b.text.trim() !== ""),
        meta: meta.reduce((acc, { key, value }) => {
          if (key.trim() && value.trim()) {
            acc[key] = value;
          }
          return acc;
        }, {} as Record<string, string>),
        conceptTags: conceptTags.filter(tag => tag.range !== null),
        ...(curriculumBoard.trim() ? { curriculumBoard } : {}),
      };
      
      await createChapterConcept(conceptData).unwrap();
      message.success("Concept created successfully!");
      refetch();
      form.resetFields();
      setBenefits([]);
      setMeta([]);
      setConceptTags([]);
      onConceptCreated();
      onCancel();
    } catch (error) {
      console.error("Error creating concept:", error);
      message.error("Error creating concept. Please try again.");
    }
  };

  return (
    <Modal
      width={900}
      title="Create New Concept"
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
      <Form.Item 
          label="Concept Name" 
          name="name" 
          rules={[{ required: true, message: 'Please enter a concept name' }]}
        >
          <Input placeholder="Enter concept name" />
        </Form.Item>

        {/* Concept Tags Section */}
        <div className="space-y-4">
          {conceptTags.map((tag, index) => (
            <Space key={index} align="baseline" className="w-full" size="large">
              <Select
                value={tag.title}
                onChange={(value) => handleConceptTagChange(index, 'title', value)}
                style={{ width: 200 }}
              >
                {Object.values(ConceptTitle).map((title) => (
                  <Select.Option key={title} value={title}>
                    {title}
                  </Select.Option>
                ))}
              </Select>

              {renderRangeInput(tag, index)}

              <Tooltip title={tag.description}>
                <InfoCircleOutlined style={{ color: '#1890ff' }} />
              </Tooltip>

              <Button 
                type="text" 
                icon={<MinusOutlined />} 
                onClick={() => removeConceptTag(index)}
                danger
              />
            </Space>
          ))}

          <Button 
            type="dashed" 
            onClick={addConceptTag} 
            block
            icon={<PlusOutlined />}
          >
            Add Concept Tag
          </Button>
        </div>

        {/* Benefits Section */}
        <h3>Benefits</h3>
        {benefits.map((benefit, index) => (
          <Space key={index} align="baseline" className="w-full" style={{ marginBottom: 8 }}>
            <Form.Item label="Benefit Text" className="flex-1">
              <Input
                value={benefit.text}
                onChange={(e) => handleBenefitChange(index, "text", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Benefit Icon">
              <Select
                value={benefit.icon}
                onChange={(value) => handleBenefitChange(index, "icon", value)}
                style={{ width: 120 }}
              >
                {Object.values(ConceptBenefitIcons).map((icon) => (
                  <Select.Option key={icon} value={icon}>
                    {icon}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button 
              type="text" 
              icon={<MinusOutlined />} 
              onClick={() => removeBenefit(index)}
              danger
            />
          </Space>
        ))}
        <Button 
          type="dashed" 
          onClick={addBenefit} 
          block 
          icon={<PlusOutlined />}
          style={{ marginBottom: 16 }}
        >
          Add Benefit
        </Button>

        {/* Meta Fields Section */}
        <h3>Meta Fields</h3>
        {meta.map((item, index) => (
          <Space key={index} className="w-full" align="baseline" style={{ marginBottom: 8 }}>
            <Form.Item label="Key">
              <Input
                value={item.key}
                onChange={(e) => handleMetaChange(index, "key", e.target.value)}
                placeholder="Meta Key"
                className="w-[300px]"
              />
            </Form.Item>
            <Form.Item label="Value">
              <Input
                value={item.value}
                onChange={(e) => handleMetaChange(index, "value", e.target.value)}
                placeholder="Meta Value"
                className="w-[300px]"
              />
            </Form.Item>
            <Button 
              type="text" 
              icon={<MinusOutlined />} 
              onClick={() => removeMetaField(index)}
              danger
            />
          </Space>
        ))}
        <Button 
          type="dashed" 
          onClick={addMetaField} 
          block
          icon={<PlusOutlined />}
        >
          Add Meta Field
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateConceptModal;