import { IMCQQuestion } from "../../components/dash-components/Mcq/types";

export enum QuestionStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  REGENERATE = "regenerate",
}

interface Option {
  isCorrect: boolean;
  text: string;
}

export type Question = IMCQQuestion;
