import React, { useState } from "react";
import { Card, Typography, Alert, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStudentsForSchoolQuery } from "./SchoolsApiSlice";
import { StudentSummaryTable } from "./StudentSummaryTable";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";

const { Title } = Typography;

const StudentsPage: React.FC = () => {
  const { schoolId } = useParams();

  // const { data: classData, isLoading: isClassDataLoading, isFetching: isClassFetching } =
  //   useGetQuestionsCatalogueQuery({});
  
  const {
    data: students,
    isLoading: studentLoading,
    isError,
    isFetching: isStudentFetching,
  } = useGetStudentsForSchoolQuery(schoolId as string, {
    skip: !schoolId,
  });

  const isLoading = studentLoading || isStudentFetching;

  return (
    <Card style={{ margin: "24px" }}>
      <Title level={2}>Students</Title>
      <div className="p-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <Spin size="large" />
          </div>
        ) : !students?.length ? (
          <Alert
            message="No Students Found"
            description="There are currently no students in the system."
            type="info"
            showIcon
          />
        ) : (
          <StudentSummaryTable
            data={students}
            // classData={classData}
            schoolId={schoolId as string}
          />
        )}
      </div>
    </Card>
  );
};

export default StudentsPage;










// import React, { useState } from "react";
// import {
//   Card,
//   Typography,
//   Alert,
// } from "antd";
// import { useNavigate, useParams } from "react-router-dom";
// import { useGetStudentsForSchoolQuery } from "./SchoolsApiSlice";
// import { StudentSummaryTable } from "./StudentSummaryTable";
// import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
// const { Title } = Typography;

// const StudentsPage: React.FC = () => {
//   const navigate = useNavigate();
  
//   const { schoolId } = useParams();
//   const { data: classData, isLoading: isClassDataLoading } =
//     useGetQuestionsCatalogueQuery({});
//   const {
//     data: students,
//     isLoading: studentLoading,
//     isError,
//     isFetching
//   } = useGetStudentsForSchoolQuery(schoolId as string, {
//     skip: !schoolId,
//   });
//   return (
//     <Card style={{ margin: "24px" }}>
//       <Title level={2}>Students</Title>
      
//       <div className="p-6">
//         {!students?.length  ? (
//           <Alert
//             message="No Students Found"
//             description="There are currently no students in the system."
//             type="info"
//             showIcon
//           />
//         ) : (
//           <StudentSummaryTable
//             data={students}
//             classData={classData}
//             schoolId={schoolId as string}
//           />
//         )}
//       </div>
//     </Card>
//   );
// };

// export default StudentsPage;


















// // const handleSearch = (value: string) => {
// //   setSearchText(value);
// //   filterStudents(value, selectedGrade, selectedSection);
// // };

// // const handleGradeChange = (value: string | null) => {
// //   setSelectedGrade(value);
// //   filterStudents(searchText, value, selectedSection);
// // };

// // const handleSectionChange = (value: string | null) => {
// //   setSelectedSection(value);
// //   filterStudents(searchText, selectedGrade, value);
// // };

//  // const handleViewReport = (studentId: string) => {
//   //   console.log(`View report for student ID: ${studentId}`);
//   //   // Implement navigation to student report page or open a modal with the report
//   // };

// {/* <Space style={{ marginBottom: 16 }}>
//         <Input
//           placeholder="Search by name"
//           onChange={(e) => handleSearch(e.target.value)}
//           style={{ width: 200 }}
//           prefix={<SearchOutlined />}
//         />
//         <Select
//           style={{ width: 120 }}
//           placeholder="Grade"
//           allowClear
//           onChange={handleGradeChange}
//         >
//           <Option value="9">Grade 9</Option>
//           <Option value="10">Grade 10</Option>
//           <Option value="11">Grade 11</Option>
//           <Option value="12">Grade 12</Option>
//         </Select>
//         <Select
//           style={{ width: 120 }}
//           placeholder="Section"
//           allowClear
//           onChange={handleSectionChange}
//         >
//           <Option value="A">Section A</Option>
//           <Option value="B">Section B</Option>
//           <Option value="C">Section C</Option>
//         </Select>
//       </Space>
//       <Table columns={columns} dataSource={filteredStudents} rowKey="id" /> */}


// // const columns: ColumnsType<Student> = [
//   //   {
//   //     title: "Name",
//   //     dataIndex: "name",
//   //     key: "name",
//   //     sorter: (a, b) => a.name.localeCompare(b.name),
//   //   },
//   //   {
//   //     title: "Grade",
//   //     dataIndex: "grade",
//   //     key: "grade",
//   //     sorter: (a, b) => a.grade.localeCompare(b.grade),
//   //   },
//   //   {
//   //     title: "Section",
//   //     dataIndex: "section",
//   //     key: "section",
//   //     sorter: (a, b) => a.section.localeCompare(b.section),
//   //   },
//   //   {
//   //     title: "Completion Rate",
//   //     dataIndex: "completionRate",
//   //     key: "completionRate",
//   //     sorter: (a, b) => a.completionRate - b.completionRate,
//   //     render: (rate) => `${rate}%`,
//   //   },
//   //   {
//   //     title: "Average Score",
//   //     dataIndex: "averageScore",
//   //     key: "averageScore",
//   //     sorter: (a, b) => a.averageScore - b.averageScore,
//   //     render: (score) => `${score}%`,
//   //   },
//   //   {
//   //     title: "Action",
//   //     key: "action",
//   //     render: (_, record) => (
//   //       <Button
//   //         type="primary"
//   //         icon={<FileTextOutlined />}
//   //         onClick={() => navigate("133")}
//   //       >
//   //         View Full Report
//   //       </Button>
//   //     ),
//   //   },
//   // ];
