import React, { useState } from "react";
import {
  Button,
  Table,
  Select,
  DatePicker,
  Space,
  Input,
  Tag,
  Avatar,
  message,
  Dropdown,
  Menu,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined, FilterOutlined, DownOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import QuizModal from "./components/QuizModal"; // Assume QuizModal is a page-level component

export interface IStudent {
  id: string;
  name: string;
  grade: number;
  section: string;
  completionRate?: number;
  averageScore?: number;
  createdAt?: string;
  remedialActivitiesTitles?: string[]; // Added this field for remedial activities
}


export interface IStudentSummaryProps {
  data: IStudent[];
  schoolId: string;
}

const { Option } = Select;

export function StudentSummaryTable({ data, schoolId }: IStudentSummaryProps) {
  const [filteredGrade, setFilteredGrade] = useState<number | undefined>();
  const [selectedDates, setSelectedDates] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(null);
  const [searchText, setSearchText] = useState("");
  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([]);
  const [isQuizModalVisible, setQuizModalVisible] = useState(false);

  const handleGradeChange = (value: number | undefined) => {
    setFilteredGrade(value);
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setSelectedDates(dates);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSendQuiz = () => {
    if (selectedStudentIds.length === 0) {
      message.warning("Please select at least one student to send a quiz.");
      return;
    }
    setQuizModalVisible(true); // Switch to QuizModal
  };

  const filteredData = data.filter((student) => {
    const isGradeMatch = filteredGrade ? student.grade === filteredGrade : true;
    const isDateMatch =
      selectedDates && selectedDates[0] && selectedDates[1]
        ? dayjs(student.createdAt).isAfter(selectedDates[0], "day") &&
          dayjs(student.createdAt).isBefore(selectedDates[1], "day")
        : true;
    const isSearchMatch = student.name
      .toLowerCase()
      .includes(searchText.toLowerCase());

    return isGradeMatch && isDateMatch && isSearchMatch;
  });

  const columns: ColumnsType<IStudent> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <div className="flex items-center">
          <Avatar size="small" className="mr-2 bg-blue-500">
            {name[0].toUpperCase()}
          </Avatar>
          <span className="font-medium">{name}</span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      render: (grade) => (
        <Tag color={`geekblue`} className="font-semibold">
          Grade {grade}
        </Tag>
      ),
      sorter: (a, b) => a.grade - b.grade,
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
    },
    {
      title: "Date Of OnBoarding",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (dateString) => {
        const formattedDate = dayjs(dateString).format("DD-MM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Completion Rate",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (rate) => (rate ? `${rate}%` : "N/A"),
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      render: (score) => (score ? `${score}%` : "N/A"),
    },
    {
      title: "Remedial Activities",
      dataIndex: "remedialActivitiesTitles",
      key: "remedialActivitiesTitles",
      render: (titles) => {
        if (titles && titles.length > 0) {
          const displayedTitles = titles.slice(0, 2); // Display only first 3 titles
          const remainingTitles = titles.slice(2); // Get the remaining titles if any
  
          return (
            <div className="flex space-x-2">
              {displayedTitles.map((title: string, index: number) => (
                <Tag key={index} color="orange" className="font-medium">
                  {title}
                </Tag>
              ))}
              {remainingTitles.length > 0 && (
                <Dropdown
                  overlay={
                    <Menu>
                      {remainingTitles.map((title: string, index: number) => (
                        <Menu.Item key={index}>{title}</Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Tag color="orange" className="font-medium cursor-pointer">
                    +{remainingTitles.length} more <DownOutlined />
                  </Tag>
                </Dropdown>
              )}
            </div>
          );
        }
        return <span>No remedial activities</span>;
      },
    },
  ];
  const resetFilters = () => {
    setFilteredGrade(undefined);
    setSelectedDates(null);
    setSearchText("");
  };

  return isQuizModalVisible ? (
    <QuizModal
      studentIds={selectedStudentIds} // Pass selected student IDs
      // schoolId={schoolId} // Pass schoolId if needed
      onClose={() => {
        setQuizModalVisible(false)
        resetFilters();
        setSelectedStudentIds([])
       }} // Switch back to table view
      showStudentsButton
    />
  ) : (
    <div>
      <Space className="mb-4 w-full flex justify-between">
        <Select
          placeholder="Select Grade"
          onChange={handleGradeChange}
          allowClear
          className="w-32"
          value={filteredGrade}
          suffixIcon={<FilterOutlined />}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <Option key={i + 1} value={i + 1}>
              Grade {i + 1}
            </Option>
          ))}
        </Select>
        <div>
          <Input.Search
            placeholder="Search students..."
            onSearch={(value) => setSearchText(value)}
            onChange={handleSearchChange}
            className="w-64"
            allowClear
            prefix={<SearchOutlined className="text-gray-400" />}
          />
          <Button
            type="primary"
            onClick={handleSendQuiz}
            disabled={selectedStudentIds.length === 0}
            className=" ml-2"
          >
            Send Quiz
          </Button>
        </div>
      </Space>

      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) =>
            setSelectedStudentIds(selectedRowKeys as string[]),
        }}
        columns={columns}
        dataSource={filteredData}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: [10, 20, 50],
        }}
      />
    </div>
  );
}