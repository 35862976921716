import { apiSlice } from "../../app/api/apiSlice";
import { CurriculumBoard } from "../EditTopics/EditTopicsApiSlice";
import { ChapterData } from "./OldShowChapters";
import { ChapterDatass } from "./types";

interface Chapter {
  // Define your Chapter type here
}

interface GetChaptersArgs {
  topic?: string | null;
  subtopic?: string | null;
  populate?: string;
  sortBy?: string;

  skip?: number;
  limit?: number;
  curriculumBoard?: CurriculumBoard | "";
}

const chaptersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChapters: builder.query<ChapterDatass[], GetChaptersArgs>({
      query: ({
        topic,
        subtopic,
        populate,
        sortBy,
        skip,
        limit,
        curriculumBoard,
      }) => {
        return {
          url: `/chapters?populate=questionIds&skip=0&limit=10&sortBy=sequence&topic=${topic}&subtopic=${subtopic}&curriculumBoard=${curriculumBoard}`,
          method: "GET",
        };
      },
    }),
    updateChapter: builder.mutation({
      query: ({ chapterId, chapterData }) => ({
        url: `/chapters/${chapterId}`,
        method: "PUT",
        body: chapterData,
      }),
    }),
    createChapter: builder.mutation<ChapterData, any>({
      // Added createChapter endpoint
      query: (createChapterData) => ({
        url: "/chapters",
        method: "POST",
        body: createChapterData,
      }),
    }),
    addSlide: builder.mutation<ChapterData, any>({
      query: ({ chapterId, slideData }) => ({
        url: `chapters/${chapterId}/slides`,
        method: "POST",
        body: slideData,
      }),
    }),
    deleteSlide: builder.mutation<
      ChapterData,
      { chapterId: string; slideId: string }
    >({
      query: ({ chapterId, slideId }) => ({
        url: `chapters/${chapterId}/slides/${slideId}`,
        method: "DELETE",
      }),
    }),
    updateSlide: builder.mutation<
      ChapterData,
      { chapterId: string; slideId: string; updateData: any }
    >({
      query: ({ chapterId, slideId, updateData }) => ({
        url: `chapters/${chapterId}/slides/${slideId}`,
        method: "PUT",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useGetChaptersQuery,
  useUpdateChapterMutation,
  useCreateChapterMutation,
  useAddSlideMutation,
  useDeleteSlideMutation,
  useUpdateSlideMutation,
} = chaptersApiSlice;
